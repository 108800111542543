import React, { FunctionComponent, ChangeEvent, useEffect } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import { autoGenerateReduxPropsNamingForContentModels } from '../../../../../../services/content-models-service/ContentModelService';
import { connect } from 'react-redux';
import { ReduxURLsProps } from '../../constants/redux-seo.attributes';
import { isJWOriginAvailable, jwPlayerOrigin } from '../../../../../Resources/Videos/Helpers/VideoHelper';
import { isCreateScreen } from '../../../../../../global-helpers/global.helpers';

type Properties = {
	t: any;
	seoTitle: string;
	contentTitle: string;
	isCopyFromTitleChecked: boolean;
	setIsCopyFromTitleChecked: (checked: boolean) => void;
	onSeoChange: (data: boolean, propertyName: ReduxURLsProps) => void;
	contentOrigin: string;
};

const SeoCopyFromTitleCheckbox: FunctionComponent<Properties> = ({
	t,
	contentTitle,
	seoTitle,
	isCopyFromTitleChecked,
	setIsCopyFromTitleChecked,
	onSeoChange,
	contentOrigin,
}) => {
	useEffect(() => {
		const isCopiedArticle = contentTitle.includes(`[${t('copy_content')}]`);

		if (isJWOriginAvailable()) {
			if (contentOrigin === jwPlayerOrigin && seoTitle.length < 1) {
				setIsCopyFromTitleChecked(true);
				onSeoChange(true, ReduxURLsProps.AUTO_TITLE);
			}
		}
		isCreateScreen() && !isCopiedArticle && setIsCopyFromTitleChecked(true);
		isCreateScreen() && !isCopiedArticle && onSeoChange(true, ReduxURLsProps.AUTO_TITLE);
	}, []);

	const onCopyFromTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const checked = event.target.checked || false;
		setIsCopyFromTitleChecked(checked);
		onSeoChange(checked, ReduxURLsProps.AUTO_TITLE);
	};

	return (
		<Row>
			<Col>
				<FormGroup check className='radio'>
					<Input
						id={DATA_QA_ATTRIBUTES.COPY_FROM_TITLE}
						className='form-check-input'
						type='checkbox'
						name='radios'
						onChange={onCopyFromTitleChange}
						checked={isCopyFromTitleChecked}
					/>
					<Label check className='form-check-label' for={DATA_QA_ATTRIBUTES.COPY_FROM_TITLE}>
						{t('generate_auto_seo_title_from_title')}
					</Label>
				</FormGroup>
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();

	return {
		contentTitle: state[tempPropertyNaming][nestedTempPropertyNaming].title || '',
		seoTitle:
			(state[tempPropertyNaming][nestedTempPropertyNaming].seo && state[tempPropertyNaming][nestedTempPropertyNaming].seo.title) || '',
		contentOrigin:
			(state[tempPropertyNaming][nestedTempPropertyNaming].origin && state[tempPropertyNaming][nestedTempPropertyNaming].origin.slug) || '',
	};
}

export default connect(mapStateToProps)(SeoCopyFromTitleCheckbox);
