import { toast } from 'react-toastify';
import { useReactQuery } from '../../../../../services/react-query/react-query.helper';
import HttpService from '../../../../../services/rest/HttpService';
import {
	FormattedSidebarSectionValue,
	FormattedSidebarSettingsResponse,
	SidebarSection,
	SidebarSettings,
	SidebarSettingsEntity,
	SidebarSettingsResponse,
} from './temp-sidebar.types';

const sidebarSettingsInitialState = {
	value: [],
	modifiedAt: 0,
};

export let defaultTempSidebarFromApiResponse: SidebarSettings = {
	article: { ...sidebarSettingsInitialState },
	category: { ...sidebarSettingsInitialState },
};

export let tempSidebar: SidebarSettings = {
	article: { ...sidebarSettingsInitialState },
	category: { ...sidebarSettingsInitialState },
};

// custom hook to extract sidebar by content type
export const useExtractSidebarSettings = (contentType: string) => {
	return useReactQuery(['sidebar', contentType], () => HttpService.getSidebarSettingsByContentType(contentType));
};

// extract and format sidebarSettings from PUT response
export const formatSidebarSettingsResponseFromPutResponse = (sidebarSettingsResponse: FormattedSidebarSettingsResponse) => {
	const sidebarKey = sidebarSettingsResponse.key.resource;
	const sidebarValue = [...sidebarSettingsResponse.sections].map((sectionObj: FormattedSidebarSectionValue) => {
		return {
			key: sectionObj.key,
			weight: sectionObj.weight,
			state: {
				opened: sectionObj.state.opened,
			},
		} as FormattedSidebarSectionValue;
	});

	const sidebarEntity: SidebarSettingsEntity = {
		value: sidebarValue as FormattedSidebarSectionValue[],
		modifiedAt: new Date().getTime(),
	};

	return { [sidebarKey]: sidebarEntity };
};
// extract and format sidebarSettings from GET response
export const formatSidebarSettingsResponseFromGetResponse = (sidebarSettingsResponse: SidebarSettingsResponse) => {
	const sidebarKey = sidebarSettingsResponse.key.resource;
	const sidebarValue = [...sidebarSettingsResponse.sections].map((sectionObj: SidebarSection) => {
		return {
			key: sectionObj.section.key,
			weight: sectionObj.weight,
			state: {
				opened: sectionObj.state.opened,
			},
		} as FormattedSidebarSectionValue;
	});

	const sidebarEntity: SidebarSettingsEntity = {
		value: sidebarValue as FormattedSidebarSectionValue[],
		modifiedAt: new Date().getTime(),
	};

	return { [sidebarKey]: sidebarEntity };
};

// check if sidebarSettings for specific content type is already fetched
export const isSidebarForSpecificContentTypeFetched = (contentType: string): boolean => {
	if (tempSidebar && tempSidebar[contentType] && tempSidebar[contentType].value && tempSidebar[contentType].modifiedAt !== 0) {
		return true;
	}

	return false;
};

// we need this because we cannot call react-query function in normal functions
// so this is the way we detect if tempSidebar is modified in edit mode without api calling
// we compare 'tempSidebar' with 'defaultTempSidebarFromApiResponse' to show the 'save changes' modal
export const createDefaultTempSidebar = (key: string) => {
	if (tempSidebar && tempSidebar[key]) {
		defaultTempSidebarFromApiResponse[key] = Object.assign({}, tempSidebar[key]);
	}
};

// set tempSidebar[ContentType] new value
export const overwriteTempSidebarByContentType = (key: string, values: FormattedSidebarSectionValue[]) => {
	const newTempSidebarValue: SidebarSettingsEntity = {
		value: values,
		modifiedAt: new Date().getTime(),
	};

	tempSidebar[key] = newTempSidebarValue;
};

// overwrite tempSidebar when new response by content type is fetched
export const overwriteTempSidebarAfterNewResponse = (
	sidebarSettingsResponse: SidebarSettingsResponse | FormattedSidebarSettingsResponse,
	isFromGetRequest: boolean = true,
) => {
	const formattedSidebarByContentType = isFromGetRequest
		? formatSidebarSettingsResponseFromGetResponse(sidebarSettingsResponse as SidebarSettingsResponse)
		: formatSidebarSettingsResponseFromPutResponse(sidebarSettingsResponse as FormattedSidebarSettingsResponse);
	const extractedContentType =
		(formattedSidebarByContentType &&
			Object.keys(formattedSidebarByContentType) &&
			Object.keys(formattedSidebarByContentType).length > 0 &&
			Object.keys(formattedSidebarByContentType)[0]) ||
		null;

	if (extractedContentType) {
		const extractedObject: SidebarSettingsEntity = formattedSidebarByContentType[extractedContentType];
		overwriteTempSidebarByContentType(extractedContentType, extractedObject.value);
		createDefaultTempSidebar(extractedContentType);
	}
};

// extract the sidebar's array value by content type
export const extractSidebarValueFromTempSidebar = (
	sidebarSettings: SidebarSettings,
	contentType: string,
): FormattedSidebarSectionValue[] => {
	let result: FormattedSidebarSectionValue[] = [];

	if (
		sidebarSettings &&
		sidebarSettings[contentType] &&
		sidebarSettings[contentType].value &&
		sidebarSettings[contentType].value.length > 0
	) {
		result = sidebarSettings[contentType].value;
	}

	return result;
};

// set tempSidebarSection(tab data)'s state new status
export const toggleTempSidebarTabData = (toggledTabData: FormattedSidebarSectionValue, isOpened: boolean): FormattedSidebarSectionValue => {
	return {
		...toggledTabData,
		state: {
			opened: isOpened,
		},
	};
};

// saving current sidebar changes for the content type in server
export const saveSidebarSettingsByContentType = async (contentType: string, t: any) => {
	try {
		const response = await HttpService.saveSidebarSettings(tempSidebar[contentType], contentType);
		if (response && response.status >= 200 && response.status < 300) {
			const responseData = (response.data as FormattedSidebarSettingsResponse) || null;
			responseData && overwriteTempSidebarAfterNewResponse(responseData, false);
			responseData && toast.success(t('save_sidebar_settings_success'));
		} else {
			toast.error(t('save_sidebar_settings_error'));
		}
	} catch (error) {
		console.error(error);
		toast.error(t('save_sidebar_settings_error'));
	}
};

// clearing current defaultTempSidebarFromApiResponse and tempSidebar by the content type
export const resetTempSidebars = (contentType: string) => {
	defaultTempSidebarFromApiResponse[contentType] = { ...sidebarSettingsInitialState };
	tempSidebar[contentType] = { ...sidebarSettingsInitialState };
};

export const appendBeforeUnloadResetTempSidebar = (contentType: string) =>
	window.addEventListener('beforeunload', () => resetTempSidebars(contentType));
