import 'reflect-metadata';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class ImageModel {
	@jsonMember(String)
	public main: string | null = null;
	@jsonMember(String)
	public mobile: string | null = null;
	@jsonMember(String)
	public cover: string | null = null;
}

@jsonObject
export class OptionModel {
	@jsonMember(String)
	public id: string | null = null;
	@jsonMember(String)
	public title: string | null = null;
	@jsonMember(String)
	public description: string | null = null;
	@jsonMember(ImageModel)
	public images: ImageModel = new ImageModel();
	@jsonMember(Number)
	public votes: number | null = null;
}

@jsonObject
export class BrandingColorsModel {
	@jsonMember(String)
	public primary_color: string | null = null;
	@jsonMember(String)
	public secondary_color: string | null = null;
	@jsonMember(String)
	public content_color: string | null = null;
	@jsonMember(String)
	public background_color: string | null = null;
	@jsonMember(String)
	public border_color: string | null = null;
	@jsonMember(String)
	public additional_color: string | null = null;
}

@jsonObject
export class BrandingUrlsModel {
	@jsonMember(String)
	public primary_url: string | null = null;
	@jsonMember(String)
	public secondary_url: string | null = null;
	@jsonMember(String)
	public privacy_policy_url: string | null = null;
	@jsonMember(String)
	public terms_and_conditions_url: string | null = null;
	@jsonMember(String)
	public additional_url: string | null = null;
}

@jsonObject
export class BrandingImagesModel {
	@jsonMember(String)
	public main_logo: string | null = null;
	@jsonMember(String)
	public mobile_logo: string | null = null;
	@jsonMember(String)
	public background_image: string | null = null;
	@jsonMember(String)
	public mobile_background_image: string | null = null;
	@jsonMember(String)
	public additional_image: string | null = null;
}

@jsonObject
export class BrandingModel {
	@jsonMember(BrandingColorsModel)
	public colors: BrandingColorsModel | null = new BrandingColorsModel();
	@jsonMember(BrandingUrlsModel)
	public urls: BrandingUrlsModel | null = new BrandingUrlsModel();
	@jsonMember(BrandingImagesModel)
	public images: BrandingImagesModel | null = new BrandingImagesModel();
}

@jsonObject
export default class FansUnitedPollModel {
	@jsonMember(String)
	public id: string | null = null;
	@jsonMember(String)
	public title: string | null = null;
	@jsonMember(String)
	public description: string | null = null;
	@jsonMember(String)
	public type: string | null = null;
	@jsonMember(String)
	public status: string | null = null;
	@jsonMember(Object)
	public labels: any | null = null;
	@jsonMember(ImageModel)
	public images: ImageModel = new ImageModel();
	@jsonArrayMember(String)
	public flags: string[] = [];
	@jsonArrayMember(OptionModel)
	public options: OptionModel[] = [];
	@jsonMember(Number)
	public total_votes: number = 0;
	@jsonMember(Object)
	public custom_fields: any | null = null;
	@jsonMember(BrandingModel)
	public branding: BrandingModel | null = new BrandingModel();
	@jsonMember(String)
	public created_at: string = '';
	@jsonMember(String)
	public updated_at: string = '';
	@jsonMember(String)
	public embed_code: string = '';
	@jsonMember(Boolean)
	public isSelected: boolean = false;
}
