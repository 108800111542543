import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import { FootballTeamH2HJson } from './football-team-h2h.json';
import FootballTeamH2HWidgetModel from './football-team-h2h.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class FootballTeamH2HBuilder {
	readonly json: FootballTeamH2HJson;

	constructor(teamH2H?: FootballTeamH2HWidgetModel | FootballTeamH2HJson) {
		if (teamH2H && teamH2H instanceof FootballTeamH2HWidgetModel) {
			this.json = teamH2H.toJson();
		} else if (teamH2H) {
			this.json = teamH2H;
		} else {
			this.json = {} as FootballTeamH2HJson;
		}
	}

	withTeamOne(team1: TeamModel): FootballTeamH2HBuilder {
		this.json.team1 = team1;

		return this;
	}

	withTeamTwo(team2: TeamModel): FootballTeamH2HBuilder {
		this.json.team2 = team2;

		return this;
	}

	withTeamOneSeason(team1TournamentSeason: EntitySeasonsSelectModel): FootballTeamH2HBuilder {
		this.json.team1TournamentSeason = team1TournamentSeason;

		return this;
	}

	withTeamTwoSeason(team2TournamentSeason: EntitySeasonsSelectModel): FootballTeamH2HBuilder {
		this.json.team2TournamentSeason = team2TournamentSeason;

		return this;
	}

	withStats(stats: string[]): FootballTeamH2HBuilder {
		this.json.stats = stats;

		return this;
	}

	withDisplayHeader(displayHeader: boolean): FootballTeamH2HBuilder {
		this.json.displayHeader = displayHeader;

		return this;
	}

	build(): FootballTeamH2HWidgetModel {
		return FootballTeamH2HWidgetModel.fromJson(this.json);
	}
}
