import { Editor } from '@tiptap/core';
import _ from 'lodash';
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import ContentWidget from '../../../../../Partials/Blocky/blocks/content/content-block/models/content-widget.model';
import BlockModel from '../../../../../Partials/Blocky/models/block.model';
import { NEW_POST_ID } from '../../../constants/PostRelated';
import CUSTOM_EXTENSIONS from '../../../helpers/collaborative-editor-extensions.helper';
import constructPostJsonData from '../../../helpers/construct-post-json-data';
import { createNewLiveBlogPost, editLiveBlogPost } from '../../../helpers/live-blog-main.helper';
import { LiveBlogPostCreatePayload, LiveBlogPostModel, PublishedType } from '../../../models/live-blog-post.model';
import { LiveBlogEditorialAdminContext, OpenModalCallback } from '../../main-components/live-blog-editorial-admin.component';
import CollaborativeEditor, { CollaborativeEditorProps, WidgetActionType } from '../collaborative-editor/collaborative-editor';
import { ToolbarAction } from '../collaborative-editor/collaborative-editor-toolbar';
import NewPostConfig, { ConfigData, NewPostConfigProps, getNewLiveBlogPostInitialConfig } from './new-post-config.component';

import '../../../style/new-post.scss';

interface LiveBlogNewPostProps {
	openModalCallback: OpenModalCallback;
	lastUpdatedWidget: CollaborativeEditorProps['lastUpdatedWidget'];
	existingPost?: (LiveBlogPostModel & { onEditEnd: () => void }) | null;
}

const NEW_LIVE_BLOG_POST_INITIAL_DATA: LiveBlogPostCreatePayload = {
	body: '',
	published_type: PublishedType.PUBLISHED,
	display_timestamp: '',
	blocks: [],
};

const EDITOR_ACTIONS = [
	ToolbarAction.BOLD,
	ToolbarAction.ITALIC,
	ToolbarAction.UNDERLINE,
	ToolbarAction.STRIKETHROUGH,
	ToolbarAction.TEXT_ALIGN_LEFT,
	ToolbarAction.TEXT_ALIGN_CENTER,
	ToolbarAction.TEXT_ALIGN_RIGHT,
	ToolbarAction.TEXT_ALIGN_JUSTIFY,
	ToolbarAction.LINK,
	ToolbarAction.TABLE,
	ToolbarAction.PARAGRAPH,
	ToolbarAction.HEADING,
	ToolbarAction.UNORDERED_LIST,
	ToolbarAction.ORDERED_LIST,
	ToolbarAction.BLOCKQUOTE,
	ToolbarAction.CODE_BLOCK,
	ToolbarAction.UNDO,
	ToolbarAction.REDO,
	ToolbarAction.CONTENT_BLOCKS,
];

const LiveBlogNewPost: FC<LiveBlogNewPostProps> = ({ openModalCallback, lastUpdatedWidget, existingPost }) => {
	const [t] = useTranslation();
	const [isDraftOptionVisible, setIsDraftOptionVisible] = useState(false);
	const initialContent: string | undefined = existingPost ? existingPost.body : undefined;
	const [initialConfig, setInitialConfig] = useState<ConfigData>(
		existingPost
			? {
					display_timestamp: existingPost.display_timestamp,
					minute: existingPost.minute,
					injury_minute: existingPost.injury_minute,
					author: existingPost.author,
					sport_event: existingPost.sport_event && (existingPost.sport_event as { id: string }).id,
					sport_tags: existingPost.sport_tags,
					sport_event_type: existingPost.sport_event_type,
					sponsors: existingPost.sponsors,
			  }
			: getNewLiveBlogPostInitialConfig(),
	);
	const [editorResetToken, setEditorResetToken] = useState<{} | null>(null);
	const [isSaveActionDisabled, setIsSaveActionDisabled] = useState(true);
	const liveBlogConfiguration = useContext(LiveBlogEditorialAdminContext);

	const data = useRef<LiveBlogPostCreatePayload>({ ...NEW_LIVE_BLOG_POST_INITIAL_DATA });

	const inEditMode = existingPost !== undefined;

	const reset = () => {
		data.current.body = NEW_LIVE_BLOG_POST_INITIAL_DATA.body;
		data.current.blocks = NEW_LIVE_BLOG_POST_INITIAL_DATA.blocks;
		delete data.current.minute;
		delete data.current.injury_minute;
		delete data.current.author;
		delete data.current.sport_event;
		delete data.current.sport_tags;
		delete data.current.sport_event_type;
		delete data.current.sponsors;

		setEditorResetToken(new Object());
		setInitialConfig(getNewLiveBlogPostInitialConfig());
		setIsSaveActionDisabled(true);
	};

	useEffect(() => {
		if (existingPost === null) {
			reset();
		}
	}, [existingPost]);

	useEffect(() => {
		if (isSaveActionDisabled) {
			setIsDraftOptionVisible(false);
		}
	}, [isSaveActionDisabled]);

	const publish = async (publishedType: PublishedType) => {
		if (!liveBlogConfiguration) {
			return;
		}

		data.current.published_type = publishedType;

		createNewLiveBlogPost(liveBlogConfiguration.id!, data.current, t('live_blog_post_create_success'), t('live_blog_post_create_error'))
			.then(() => {
				reset();
			})
			.catch((e) => {
				setIsSaveActionDisabled(false);
			});

		setIsSaveActionDisabled(true);
	};

	const save = (publishedType: PublishedType) => {
		if (!existingPost) {
			return;
		}

		const { id, created_at, onEditEnd, ...dataFromDB } = existingPost;

		data.current.published_type = publishedType;

		if (data.current.body === NEW_LIVE_BLOG_POST_INITIAL_DATA.body) {
			data.current.body = existingPost.body;
			data.current.blocks = existingPost.blocks;
		}

		editLiveBlogPost(
			liveBlogConfiguration!.id!,
			existingPost.id,
			{ ...dataFromDB, ...data.current },
			t('live_blog_post_update_success'),
			t('live_blog_post_update_error'),
		)
			.then(() => {
				reset();
				existingPost && existingPost.onEditEnd();
			})
			.catch((e) => undefined);
	};

	const cancel = () => existingPost && existingPost.onEditEnd();

	const onWidgetBeginEdit: CollaborativeEditorProps['onWidgetBeginEdit'] = ({ attrs, subDocumentId }) => {
		let block: BlockModel | undefined;
		let content: ContentWidget | undefined;

		try {
			block = JSON.parse(attrs['data-block']);
			content = JSON.parse(attrs['data-content']);
		} catch (e) {
			toast.error(t('parsing_error'));
			console.error(e);
		}

		if (!block || !content) {
			return;
		}

		openModalCallback(block, WidgetActionType.EDIT, content, subDocumentId);
	};

	const onContentChange = useCallback(
		_.debounce<(editor: Editor) => void>((editor) => {
			const body: string = editor.getHTML();

			data.current.body = body;
			data.current.blocks = constructPostJsonData(body, editor.getJSON());
			setIsSaveActionDisabled(body.length === 0 || body === '<p></p>');
		}, 500),
		[],
	);

	const onConfigChange: NewPostConfigProps['onDataChange'] = useCallback(
		({ display_timestamp, minute, injury_minute, author, sport_event, sport_tags, sponsors, sport_event_type }) => {
			data.current.display_timestamp = display_timestamp;
			data.current.minute = minute || undefined;
			data.current.injury_minute = injury_minute || undefined;
			data.current.author = author ? { id: author.id!, name: author.name! } : undefined;
			data.current.sport_event = sport_event || undefined;
			data.current.sport_tags = sport_tags || undefined;
			data.current.sport_event_type = sport_event_type || undefined;
			data.current.sponsors = sponsors || undefined;
		},
		[],
	);

	const subDocumentId = existingPost ? existingPost.id : NEW_POST_ID;

	return (
		<div className='live-blog-editorial-admin-new-post-container'>
			<CollaborativeEditor
				editable
				content={initialContent}
				customNodes={CUSTOM_EXTENSIONS}
				lastUpdatedWidget={lastUpdatedWidget}
				onWidgetBeginEdit={onWidgetBeginEdit}
				onUpdate={onContentChange}
				subDocumentId={subDocumentId}
				resetToken={editorResetToken}
				openModalCallback={openModalCallback}
				actions={EDITOR_ACTIONS}
			/>
			<NewPostConfig initialData={initialConfig} onDataChange={onConfigChange} autoTime={!inEditMode} />
			{inEditMode && existingPost ? (
				<div className='live-blog-editorial-admin-new-post-footer'>
					<Button outline color='secondary' onClick={cancel}>
						{t('cancel')}
					</Button>
					<Button
						color={existingPost.published_type === PublishedType.PUBLISHED ? 'success' : 'primary'}
						outline={existingPost.published_type === PublishedType.DRAFT}
						onClick={() => save(existingPost.published_type)}
					>
						{t('save')}
					</Button>
					{existingPost.published_type === PublishedType.DRAFT && (
						<Button color='success' onClick={() => save(PublishedType.PUBLISHED)}>
							{t('publish')}
						</Button>
					)}
				</div>
			) : (
				<ButtonDropdown
					isOpen={isDraftOptionVisible}
					toggle={() => setIsDraftOptionVisible((prev) => !prev)}
					className='live-blog-editorial-admin-new-post-publish-button'
					disabled={isSaveActionDisabled}
				>
					<Button color='success' onClick={() => publish(PublishedType.PUBLISHED)} disabled={isSaveActionDisabled}>
						{t('publish')}
					</Button>
					<DropdownToggle split color='success' disabled={isSaveActionDisabled} />
					<DropdownMenu color='success'>
						<DropdownItem color='success' onClick={() => publish(PublishedType.DRAFT)}>
							{t('save_as_draft')}
						</DropdownItem>
					</DropdownMenu>
				</ButtonDropdown>
			)}
		</div>
	);
};

export default LiveBlogNewPost;
