import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import { FootballTeamH2HJson } from './football-team-h2h.json';
import FootballTeamH2HBuilder from './football-team-h2h.builder';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class FootballTeamH2HWidgetModel {
	readonly team1: TeamModel;
	readonly team2: TeamModel;
	readonly team1TournamentSeason: EntitySeasonsSelectModel;
	readonly team2TournamentSeason: EntitySeasonsSelectModel;
	readonly stats: string[];
	readonly displayHeader: boolean = true;

	private constructor(
		team1: TeamModel,
		team2: TeamModel,
		team1TournamentSeason: EntitySeasonsSelectModel,
		team2TournamentSeason: EntitySeasonsSelectModel,
		stats: string[],
		displayHeader: boolean = true,
	) {
		this.team1 = team1;
		this.team2 = team2;
		this.team1TournamentSeason = team1TournamentSeason;
		this.team2TournamentSeason = team2TournamentSeason;
		this.stats = stats;
		this.displayHeader = displayHeader;
	}

	toJson(): FootballTeamH2HJson {
		return {
			team1: this.team1,
			team2: this.team2,
			team1TournamentSeason: this.team1TournamentSeason,
			team2TournamentSeason: this.team2TournamentSeason,
			stats: this.stats,
			displayHeader: this.displayHeader,
		};
	}

	static fromJson(json: FootballTeamH2HJson): FootballTeamH2HWidgetModel {
		return new FootballTeamH2HWidgetModel(
			json.team1,
			json.team2,
			json.team1TournamentSeason,
			json.team2TournamentSeason,
			json.stats,
			json.displayHeader,
		);
	}

	static builder(model?: FootballTeamH2HWidgetModel): FootballTeamH2HBuilder {
		return new FootballTeamH2HBuilder(model);
	}
}
