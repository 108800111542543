import React, { FunctionComponent, useState } from 'react';
import 'flatpickr/dist/themes/material_green.css';
import Flatpickr from 'react-flatpickr';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import './DateTimePickerContainer.scss';
import { dateFormat, dateFormatWithoutHours, langs } from '../../constants/date-time.attributes';

type Properties = {
	t: any;
	language: string;
	date: string;
	onChange: (dates: Date[] | null) => void;
	id: string;
	timezone: string;
	minDate?: string;
	isInvalid?: boolean;
	withoutTime?: boolean;
	placeholder?: string;
};

const DateTimeSelect: FunctionComponent<Properties> = ({
	t,
	language,
	date,
	onChange,
	id,
	timezone,
	minDate,
	isInvalid,
	withoutTime,
	placeholder,
}) => {
	//@ts-ignore
	let timezonedDate = moment.tz(moment(date), dateFormat, timezone);
	const [parsedDate, setParsedDate] = useState<Date | null>(
		date
			? new Date(
					timezonedDate.year(),
					timezonedDate.month(),
					timezonedDate.date(),
					timezonedDate.hour(),
					timezonedDate.minute(),
					timezonedDate.second(),
			  )
			: null,
	);
	let flatpickrInstance: any = null;

	const getDefaultTime = () => {
		const now = moment.tz(timezone);
		return new Date(now.year(), now.month(), now.date(), now.hour(), now.minute(), now.second());
	};

	return (
		<div className='datepicker-container'>
			<Flatpickr
				ref={(ref) => (flatpickrInstance = ref && ref.flatpickr)}
				className={`date-picker ${isInvalid ? 'invalid-input' : ''} `}
				id={id}
				options={{
					defaultDate: date || '',
					enableTime: !withoutTime,
					dateFormat: withoutTime ? dateFormatWithoutHours : dateFormat,
					time_24hr: true,
					locale: langs[language],
					minDate: minDate,
					onOpen: (selectedDates, dateStr, instance) => {
						if (!parsedDate) {
							const defaultTime = getDefaultTime();
							instance.setDate(defaultTime);
							setParsedDate(defaultTime);
							onChange([defaultTime]);
						}
					},
				}}
				placeholder={placeholder ? placeholder : t('date_mode_format')}
				onChange={(dates) => {
					if (dates && dates.length > 0) {
						setParsedDate(dates[0]);
						onChange(dates);
					} else {
						setParsedDate(null);
						onChange(null);
					}
				}}
				value={parsedDate}
			/>
			<div
				id={`${id}-clear-button`}
				className='close-button'
				onClick={() => {
					if (flatpickrInstance) {
						flatpickrInstance.clear();
					}
					setParsedDate(null);
					onChange(null);
				}}
			>
				<i className='fa fa-times close-icon' />
			</div>
		</div>
	);
};

function mapStateToProps(state: any) {
	return {
		language: state.profile.profile.language || 'en-US',
		timezone: state.project.currentProject.timezone || 'Europe/Sofia',
	};
}
export default compose(connect(mapStateToProps))(DateTimeSelect);
