import React from 'react';
import { Col, Row } from 'reactstrap';
import FootballTeamH2HWidgetModel from '../models/football-team-h2h.model';
import { teamH2HStatsOptions } from '../../football-team/constants/football-team.constants';
import { generateDisplayHeaderOptionViewText } from '../../../partials/header-display-input/helper';

type Properties = {
	preview: FootballTeamH2HWidgetModel | any;
	t: any;
};

export const FootballTeamH2hViewComponent: React.FunctionComponent<Properties> = ({ preview, t }) => {
	const stats = preview.stats && preview.stats.length > 0 ? preview.stats : teamH2HStatsOptions;
	if (preview.team1 && preview.team1.id && preview.team2 && preview.team2.id) {
		return (
			<>
				<Row data-qa='football-team-h2h-view' className={'pb-4 pl-4 pr-4'}>
					<Col className='text-center'>
						<img
							className='rounded-circle'
							height='50'
							src={preview.team1 && preview.team1.urlLogo && preview.team1.urlLogo}
							alt={preview.team1.name}
						/>
						<h4>{preview.team1.name}</h4>
						{preview.team1TournamentSeason && preview.team1TournamentSeason.seasonId && (
							<div className='single-row'>
								{preview.team1TournamentSeason.tournamentName} - {preview.team1TournamentSeason.seasonName}
							</div>
						)}
					</Col>
					<Col className='mr-3 d-flex flex-column justify-content-center align-items-center'>
						<span>{t('vs')}</span>
					</Col>
					<Col className='text-center'>
						<img
							className='rounded-circle'
							height='50'
							src={preview.team2 && preview.team2.urlLogo && preview.team2.urlLogo}
							alt={preview.team2.name}
						/>
						<h4>{preview.team2.name}</h4>
						{preview.team2TournamentSeason && preview.team2TournamentSeason.seasonId && (
							<div className='single-row'>
								{preview.team2TournamentSeason.tournamentName} - {preview.team2TournamentSeason.seasonName}
							</div>
						)}
					</Col>
					<Row>
						{stats && stats.length > 0 && (
							<Col>
								<strong>{t('selected_stats')}:</strong>
								{stats.map((stat: string, index: number) => {
									return (
										<span key={`team-h2h-stat-key-${index}`} className='pl-1'>
											{t(`${stat}_title`)}
											{index + 1 === stats.length ? '' : ','}
										</span>
									);
								})}
							</Col>
						)}
					</Row>
				</Row>
				{generateDisplayHeaderOptionViewText(preview.displayHeader)}
			</>
		);
	}

	return null;
};
