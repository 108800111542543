import ErrorResponseModel from '../../models/error-response/ErrorResponseModel';
import Project from '../../models/project/Project';
import Category from '../../models/category/Category';
import { ContentMode } from '../../constants/content-types';

export const ALL_CATEGORY_LISTING_REQUEST = '[ACTION] ALL_CATEGORY_LISTING_REQUEST';
export const ALL_CATEGORY_LISTING_RECEIVED = '[ACTION] ALL_CATEGORY_LISTING_RECEIVED';
export const ALL_CATEGORY_LISTING_FAILED = '[ACTION] ALL_CATEGORY_LISTING_FAILED';

export const CATEGORY_LISTING_REQUEST = '[ACTION] CATEGORY_LISTING_REQUEST';
export const CATEGORY_LISTING_RECEIVED = '[ACTION] CATEGORY_LISTING_RECEIVED';
export const CATEGORY_LISTING_FAILED = '[ACTION] CATEGORY_LISTING_FAILED';

export const ADMIN_CATEGORY_LISTING_RECEIVED = '[ACTION] ADMIN_CATEGORY_LISTING_RECEIVED';
export const ADMIN_CATEGORY_LISTING_FAILED = '[ACTION] ADMIN_CATEGORY_LISTING_FAILED';

export const CATEGORY_ENTITY_DELETE = '[ACTION] CATEGORY_ENTITY_DELETE';
export const CATEGORY_ENTITY_DELETE_SUCCESS = '[ACTION] CATEGORY_ENTITY_DELETE_SUCCESS';
export const CATEGORY_ENTITY_DELETE_FAILED = '[ACTION] CATEGORY_ENTITY_DELETE_FAILED';

export const CATEGORY_SEARCH = '[ACTION] CATEGORY_SEARCH';
export const CATEGORY_SEARCH_FAILED = '[ACTION] CATEGORY_SEARCH_FAILED';

export const CATEGORY_ENTITY_CREATE = '[ACTION] CATEGORY_ENTITY_CREATE';
export const CATEGORY_ENTITY_CREATE_SUCCESS = '[ACTION] CATEGORY_ENTITY_CREATE_SUCCESS';
export const CATEGORY_ENTITY_CREATE_FAILED = '[ACTION] CATEGORY_ENTITY_CREATE_FAILED';

export const CATEGORY_ENTITY_UPDATE = '[ACTION] CATEGORY_ENTITY_UPDATE';
export const CATEGORY_ENTITY_UPDATE_FAILED = '[ACTION] CATEGORY_ENTITY_UPDATE_FAILED';
export const CATEGORY_ENTITY_UPDATE_SUCCESS = '[ACTION] CATEGORY_ENTITY_UPDATE_SUCCESS';

export const CATEGORY_ENTITY_REQUEST = '[ACTION] CATEGORY_ENTITY_REQUEST';
export const CATEGORY_ENTITY_RECEIVED = '[ACTION] CATEGORY_ENTITY_RECEIVED';
export const CATEGORY_ENTITY_REQUEST_FAILED = '[ACTION] CATEGORY_ENTITY_REQUEST_FAILED';
export const CATEGORY_PROPERTY_UPDATE = '[ACTION] CATEGORY_PROPERTY_UPDATE';

export function returnObjectForAllCategoryListingReceived(categories: any) {
	return {
		type: ALL_CATEGORY_LISTING_RECEIVED,
		payload: categories,
	};
}

export function returnObjectForCategoryListingFailed(error: any) {
	return {
		type: ALL_CATEGORY_LISTING_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function returnObjectForCategoryListingReceived(categories: any) {
	return {
		type: CATEGORY_LISTING_RECEIVED,
		payload: categories,
	};
}

export function returnObjectForAdminCategoryListingReceived(categories: any) {
	return {
		type: ADMIN_CATEGORY_LISTING_RECEIVED,
		payload: categories,
	};
}

export function returnObjectForAdminCategoryListingFailed(error: any) {
	return {
		type: ADMIN_CATEGORY_LISTING_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function returnObjectForCategoryListingRequest(page: string, project: Project, adminId: string) {
	return {
		type: CATEGORY_LISTING_REQUEST,
		payload: { page, project, adminId },
	};
}

export function returnObjectForAllCategoryListingRequest(page: string, project: Project, adminId: string) {
	return {
		type: ALL_CATEGORY_LISTING_REQUEST,
		payload: { page, project, adminId },
	};
}

export function returnObjectForCategoryEntityCreate(category: Category, project: Project) {
	return {
		type: CATEGORY_ENTITY_CREATE,
		payload: { category, project },
	};
}

export function returnObjectForCategoryEntityCreateSuccess(id: string) {
	return {
		type: CATEGORY_ENTITY_CREATE_SUCCESS,
		payload: id,
	};
}

export function returnObjectForCategoryEntityUpdate(category: Category, project: Project) {
	return {
		type: CATEGORY_ENTITY_UPDATE,
		payload: { category, project },
	};
}

export function returnObjectForCategoryEntityUpdateSuccess() {
	return {
		type: CATEGORY_ENTITY_UPDATE_SUCCESS,
	};
}

export function returnObjectForCategoryEntityUpdateFailed(error?: ErrorResponseModel) {
	return {
		type: CATEGORY_ENTITY_UPDATE_FAILED,
		payload: error ? error : '',
	};
}

export function returnObjectForCategoryEntityCreateFailed(error?: ErrorResponseModel) {
	return {
		type: CATEGORY_ENTITY_CREATE_FAILED,
		payload: error ? error : '',
	};
}

export function returnObjectForCategoryEntityRequest(id: string, project: Project) {
	return {
		type: CATEGORY_ENTITY_REQUEST,
		payload: { id, project },
	};
}

export function returnObjectForCategoryEntityReceived(category: any) {
	return {
		type: CATEGORY_ENTITY_RECEIVED,
		payload: category,
	};
}

export function returnObjectForCategorySearch(text: string, project: Project) {
	return {
		type: CATEGORY_SEARCH,
		payload: { text, project },
	};
}

export function returnObjectForCategorySearchFailed(error: any) {
	return {
		type: CATEGORY_SEARCH_FAILED,
		payload: ErrorResponseModel.builder().fromResponse(error).build(),
	};
}

export function returnObjectForCategoryEntityDelete(id: string, project: Project) {
	return {
		type: CATEGORY_ENTITY_DELETE,
		payload: { id, project },
	};
}

export function returnObjectForCategoryEntityDeleteSuccess() {
	return {
		type: CATEGORY_ENTITY_DELETE_SUCCESS,
	};
}

export function returnObjectForCategoryEntityDeleteFailed(error: any) {
	return {
		type: CATEGORY_ENTITY_DELETE_FAILED,
		payload: error.response.data.message,
	};
}

export function updateCategoryProperty(propertyObject: Record<string, any>) {
	return {
		type: CATEGORY_PROPERTY_UPDATE,
		payload: propertyObject,
	};
}
