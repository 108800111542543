import { IMatch, ISelectOption } from '../../../types/player';
import PlayerOrTeamMatchModel from '../../match-select/models/player-or-team-match.model';

export const remapMatchesResponse = (data: IMatch[]): PlayerOrTeamMatchModel[] => {
	return data.map(
		(item: IMatch) =>
			({
				id: item.id,
				status: item.status,
				homeTeam: item.home_team,
				awayTeam: item.away_team,
			} as PlayerOrTeamMatchModel),
	);
};

export const matchesOptions = (data: PlayerOrTeamMatchModel[]): ISelectOption<PlayerOrTeamMatchModel>[] => {
	if (data && data.length > 0) {
		return data.map((item) => ({
			label: `${item.homeTeam.name} - ${item.awayTeam.name} (${item.status.name})`,
			value: item.id,
			data: item,
		}));
	}

	return [];
};

export const matchOption = (item: PlayerOrTeamMatchModel) => {
	if (item && item.homeTeam && item.homeTeam.name && item.awayTeam && item.awayTeam.name && item.status && item.status.name) {
		return {
			label: `${item.homeTeam.name} - ${item.awayTeam.name} (${item.status.name})`,
			value: item.id,
			data: item,
		};
	}
	return {};
};
