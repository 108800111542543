import React, { useEffect, useState } from 'react';
import * as ReactSortableHOC from 'react-sortable-hoc';
import ContentAttributes from '../../../../models/content-attributes/content-attributes-model';
import SidebarSortableElementsList from './subcomponents/sidebar-sortable-list';
import Project from '../../../../models/project/Project';
import {
	isReactQueryError,
	isReactQueryFetchedSuccessfully,
	isReactQueryLoading,
} from '../../../../services/react-query/react-query.helper';
import {
	isSidebarForSpecificContentTypeFetched,
	overwriteTempSidebarAfterNewResponse,
	resetTempSidebars,
	tempSidebar,
	useExtractSidebarSettings,
} from './utils/temp-sidebar.helper';
import { FormattedSidebarSectionValue } from './utils/temp-sidebar.types';
import { toast } from 'react-toastify';

type Properties = {
	sidebarComponentsMap: any;
	contentType: string;
	t: any;
	types?: ContentAttributes[];
	statuses?: ContentAttributes[];
	commentsPolicies?: ContentAttributes[];
	onElemSort: (elem: any) => void;
	onChangeCollapseStatus: (elem: any) => void;
	isSidebarInEdit: boolean;
	contentMode?: string;
	currentProject?: Project;
};

const SidebarSortableList = ReactSortableHOC.SortableContainer(SidebarSortableElementsList);

const SidebarCollapsibleElements: React.FunctionComponent<Properties> = (props) => {
	const {
		onElemSort,
		sidebarComponentsMap,
		contentType,
		t,
		types,
		statuses,
		commentsPolicies,
		onChangeCollapseStatus,
		isSidebarInEdit,
		contentMode,
		currentProject,
	} = props;
	const [rerender, setRerender] = useState(false); // use setRerender() only if you want to force rerender the current component

	const extractedSidebarSettings = useExtractSidebarSettings(contentType);
	const reactQueryStatus = extractedSidebarSettings.status;
	const reactQueryData = (extractedSidebarSettings && extractedSidebarSettings.data && extractedSidebarSettings.data.data) || null;
	if (!isSidebarForSpecificContentTypeFetched(contentType) && isReactQueryFetchedSuccessfully(reactQueryStatus) && reactQueryData) {
		overwriteTempSidebarAfterNewResponse(reactQueryData);
	} else if (isReactQueryError(reactQueryStatus)) {
		toast.error(t('problem_fetching_sidebar'));
	}

	useEffect(() => {
		// Reset sidebar settings when currentProject changes
		resetTempSidebars(contentType);

		// Fetch updated sidebar settings
		if (!isSidebarForSpecificContentTypeFetched(contentType) && isReactQueryFetchedSuccessfully(reactQueryStatus) && reactQueryData) {
			overwriteTempSidebarAfterNewResponse(reactQueryData);
		}

		// Cleanup function: reset sidebar settings when component unmounts
		return () => {
			resetTempSidebars(contentType);
		};
	}, [currentProject]);

	const onSortEnd = (sortEnd: ReactSortableHOC.SortEnd) => {
		let settings = { ...tempSidebar };
		if (settings[contentType] && settings[contentType].value && settings[contentType].value.length > 0) {
			let newElements: FormattedSidebarSectionValue[] = settings[contentType].value.slice();
			newElements = ReactSortableHOC.arrayMove([...newElements], sortEnd.oldIndex, sortEnd.newIndex);
			newElements.forEach((element: FormattedSidebarSectionValue, elIndex: number) => (element.weight = elIndex + 1));
			onElemSort(newElements);
			setRerender(!rerender);
		}
	};

	if (!isReactQueryLoading(reactQueryStatus) && reactQueryData) {
		return (
			<SidebarSortableList
				isSidebarInEdit={isSidebarInEdit}
				onChangeCollapseStatus={onChangeCollapseStatus}
				t={t}
				useDragHandle
				statuses={statuses}
				types={types}
				contentType={contentType}
				onSortEnd={onSortEnd}
				commentsPolicies={commentsPolicies}
				sidebarComponentsMap={sidebarComponentsMap}
				sidebarSettings={tempSidebar[contentType]}
				contentMode={contentMode}
				project={currentProject}
			/>
		);
	} else if (isReactQueryError(reactQueryStatus)) {
		return <></>;
	} else {
		return <div className='loading-overlay'></div>;
	}
};

export default SidebarCollapsibleElements;
