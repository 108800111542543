import React from 'react';
import { Row, Col, Label, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Title } from '../../Partials/Fields/title/TitleComponent';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Project from '../../../models/project/Project';
import Category from '../../../models/category/Category';
import { Properties, State } from './Properties/CategoryCreateProperties';
import {
	CATEGORY_ENTITY_CREATE,
	CATEGORY_ENTITY_CREATE_FAILED,
	CATEGORY_ENTITY_CREATE_SUCCESS,
	returnObjectForCategoryEntityCreate,
	returnObjectForCategoryListingRequest,
} from '../../../store/action-creators/CategoriesActionCreators';
import { Description } from '../../Partials/Fields/description/DescriptionComponent';
import { SeoComponentModel } from '../../Partials/Sidebar/Seo/models/SeoComponentModel';
import {
	seoComponentModelToCategory,
	categoryToSeoComponentModelForCreate,
	categoryToUrlsComponentModelForCreate,
	urlsComponentModelToCategory,
} from './Helpers/CategoryHelper';
import Seo from '../../Partials/Sidebar/Seo/SeoComponent';
import MainMedia from '../../../models/mainmedia/MainMedia';
import MainMediaContainer from '../../Partials/Sidebar/Media/MainMediaListContainer';
import { Active } from '../../Partials/Fields/active/ActiveComponent';
import Urls from '../../Partials/Sidebar/Urls/urls.component';
import { UrlsComponentModel } from '../../Partials/Sidebar/Urls/models/UrlsComponentModel';
import BlockableButtonWrapper from '../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';
import { ContentTypes, DynamicObjectWithKeys } from '../../../constants/content-types';
import SeoModel from '../../../models/seo/Seo';
import SeoBuilder from '../../../models/seo/SeoBuilder';
import SidebarCollapsibleElements from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-collapsible-elements';
import SidebarElementsToggle from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-elements-toggle/sidebar-elements-toggle.component';
import {
	ContentSidebarChangeCollapseStatus,
	onChangeCollapseStatus,
	onSidebarElemSort,
	toggleSidebarEditMode,
} from '../../../services/content-sidebar/content-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';
import GlobalModal from '../../Partials/Modals/Global/global-modal';
import {
	appendBeforeUnloadResetTempSidebar,
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	saveSidebarSettingsByContentType,
} from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';
import ContentProperties from '../../Partials/Sidebar/general-content/components/ContentProperties';

class CategoryCreate extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			isSidebarInEdit: false,
			category: Category.builder()
				.withActive(true)
				.withSeo(new SeoBuilder().withFollow(true).withIndex(true).build())
				.withInitialDynamicProperties(Category.extractDynamicPropertiesAsObjectWithValues())
				.build(),
			openSidebarSettingsModalFlag: false,
		};
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.initCategoryCreateSuccessListener();
		appendBeforeUnloadResetTempSidebar(ContentTypes.CATEGORY);
	}

	initPageTitle() {
		document.title = this.props.t('category_create');
	}

	initCategoryCreateSuccessListener() {
		window.addEventListener(CATEGORY_ENTITY_CREATE_SUCCESS, (data: any) => {
			this.props.getCategories(1, this.props.project, this.props.adminId);
			this.props.history.push(`/smp/categories/edit/${data.detail}`);
		});
	}

	onTitleChange(title: string) {
		const seo = SeoModel.builder(this.state.category.seo).withAutoUpdateSeo(title).build();
		this.setState({ category: Category.builder(this.state.category).withSeo(seo).withTitle(title).build() });
	}

	onDescriptionChange(description: string) {
		this.setState({ category: Category.builder(this.state.category).withDescription(description).build() });
	}

	onActiveChange(active: boolean) {
		this.setState({ category: Category.builder(this.state.category).withActive(active).build() });
	}

	onSeoChange(seoModel: SeoComponentModel) {
		const category = seoComponentModelToCategory(seoModel, this.state.category);
		this.setState({ category });
	}

	onUrlsChange(urlsModel: UrlsComponentModel) {
		const category = urlsComponentModelToCategory(urlsModel, this.state.category);
		this.setState({ category });
	}

	onMainMediaChange(mainMedia: MainMedia[]) {
		let category = Category.builder(this.state.category).withMainMedia(mainMedia).build();
		this.setState({ category });
	}

	onDynamicPropertiesChange(dynamicProperties: { key: string; checked: boolean }) {
		try {
			const currentProperties = { ...this.state.category.properties };
			currentProperties[dynamicProperties.key] = dynamicProperties.checked;

			const formattedData = Object.keys(currentProperties).map((dynamicPropertyKey) => ({
				name: dynamicPropertyKey,
				slug: dynamicPropertyKey,
				value: !!currentProperties[dynamicPropertyKey],
			}));

			const category = Category.builder(this.state.category).withDynamicProperties(formattedData).build();
			this.setState({ category });
		} catch (e) {
			console.error('Error updating dynamic properties:', e);
		}
	}

	toggleSidebarEditMode = () => {
		const newState = toggleSidebarEditMode({ ...this.state }, ContentTypes.CATEGORY);
		this.setState(newState);
	};

	onSubmit() {
		this.props.postCategory(this.state.category, this.props.project);
	}

	render() {
		const { t, project } = this.props;
		const { isSidebarInEdit, openSidebarSettingsModalFlag, category } = this.state;

		const seoModel = categoryToSeoComponentModelForCreate(this.state.category);
		const urlsModel = categoryToUrlsComponentModelForCreate(this.state.category);

		const sidebarComponentsMap = {
			general: (
				<ContentProperties
					contentInitialProperties={category.properties}
					onDynamicPropertiesChange={this.onDynamicPropertiesChange.bind(this)}
				/>
			),
			media: (
				<MainMediaContainer
					onChange={this.onMainMediaChange.bind(this)}
					value={this.state.category.mainMedia}
					currentProject={project}
					contentType={ContentTypes.CATEGORY}
					applyWatermark={false}
				/>
			),
			urls: <Urls contentType={ContentTypes.CATEGORY} onChange={this.onUrlsChange.bind(this)} value={urlsModel} />,
			seo: <Seo onChange={this.onSeoChange.bind(this)} value={seoModel} />,
		};

		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col='8' lg='8' md='12' sm='12' xs='12'>
						<div className='card'>
							<div className='card-header'>
								<div className={'d-flex align-items-center'}>
									<span className={'mr-auto mb-0'}>{t('category_create_title')}</span>
									<BlockableButtonWrapper
										blockOnActions={[CATEGORY_ENTITY_CREATE]}
										releaseOnActions={[CATEGORY_ENTITY_CREATE_SUCCESS, CATEGORY_ENTITY_CREATE_FAILED]}
									>
										<Button size='sm' color='primary' id='category-create-save-top' className={'ml-auto'} onClick={this.onSubmit.bind(this)}>
											<i className='fa fa-floppy-o'></i> {t('save_category')}
										</Button>
									</BlockableButtonWrapper>
								</div>
							</div>
							<div className='card-body'>
								<Row>
									<Col xs='12'>
										<Label htmlFor='title'>{t('title')}</Label>
										<Title t={t} onChange={this.onTitleChange.bind(this)} value={this.state.category.title} dataQaProperty={'default-title'} />
									</Col>
								</Row>
								<Row>
									<Col xs='12'>
										<Description t={t} onChange={this.onDescriptionChange.bind(this)} showValidation={true} value={this.state.category.description} />
									</Col>
								</Row>
								<Row>
									<Col xs='12'>
										<Active
											t={t}
											onChange={this.onActiveChange.bind(this)}
											value={this.state.category.active}
											isChecked={this.state.category.active}
										/>
									</Col>
								</Row>
								<Row className={'mt-4'}>
									<Col xs='12'>
										<BlockableButtonWrapper
											blockOnActions={[CATEGORY_ENTITY_CREATE]}
											releaseOnActions={[CATEGORY_ENTITY_CREATE_SUCCESS, CATEGORY_ENTITY_CREATE_FAILED]}
										>
											<Button
												color={'primary'}
												id='category-create-save-bottom'
												className={'text-uppercase font-weight-bold'}
												onClick={this.onSubmit.bind(this)}
											>
												<i className='fa fa-floppy-o'></i> {t('save_category')}
											</Button>
										</BlockableButtonWrapper>
									</Col>
								</Row>
							</div>
						</div>
					</Col>
					<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
						<SidebarElementsToggle t={t} toggleSidebarEditMode={this.toggleSidebarEditMode} isSidebarInEdit={isSidebarInEdit} />
						<SidebarCollapsibleElements
							isSidebarInEdit={isSidebarInEdit}
							onElemSort={(settingsValue: FormattedSidebarSectionValue[]) => onSidebarElemSort(settingsValue, ContentTypes.CATEGORY)}
							onChangeCollapseStatus={(data: ContentSidebarChangeCollapseStatus) => onChangeCollapseStatus(data)}
							contentType={ContentTypes.CATEGORY}
							sidebarComponentsMap={sidebarComponentsMap}
							t={t}
						/>
					</Col>
				</Row>
				<GlobalModal
					isOpen={openSidebarSettingsModalFlag}
					t={this.props.t}
					headerContent='save_sidebar_settings'
					bodyContent='unsaved_changes'
					submitFunction={() => saveSidebarSettingsByContentType(ContentTypes.CATEGORY, t)}
					beforeCloseFunction={() =>
						overwriteTempSidebarByContentType(ContentTypes.CATEGORY, defaultTempSidebarFromApiResponse[ContentTypes.CATEGORY].value)
					}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		category: state.category.category,
		adminId: state.profile.profile.id,
		project: state.project.currentProject,
		profile: state.profile.profile,
		errors: state.general.errors,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		getCategories: (page: string, project: Project, adminId: string) => {
			dispatch(returnObjectForCategoryListingRequest(page, project, adminId));
		},
		postCategory: (category: Category, project: Project) => dispatch(returnObjectForCategoryEntityCreate(category, project)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(CategoryCreate) as React.ComponentType;
