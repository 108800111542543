import Author from '../author/Author';
import Urls from '../urls/Urls';
import Seo from '../seo/Seo';
import Category from '../category/Category';
import { ArticleAutoTaggingScope, ArticleJson } from './ArticleJson';
import ArticleBuilder from './ArticleBuilder';
import { IContent } from '../IContent';
import MainMedia from '../mainmedia/MainMedia';
import ContentAttributes from '../content-attributes/content-attributes-model';
import BlockModel from '../../views/Partials/Blocky/models/block.model';
import TranslationGroupModel from '../translation-groups/translation-group-model';
import { store } from '../../store/store';
import { DynamicObjectWithKeys } from '../../constants/content-types';
import { VersionModel } from '../v2/meta-data-versions/versions.model';

export default class Article implements IContent {
	readonly id: string;
	readonly title: string;
	readonly subtitle: string;
	readonly strapline: string;
	readonly type: string;
	readonly body: BlockModel[];
	readonly publishedAt: string;
	readonly createdAt: string;
	readonly updatedAt: string;
	readonly publishedUntil: string;
	readonly status: string;
	readonly comments: any;
	readonly customAuthor: string;
	readonly language: string;
	readonly authors: Author[] = [];
	readonly image: any;
	readonly generic: any;
	readonly urls: Urls;
	readonly seo: Seo;
	readonly category: Category;
	readonly additionalCategories: Category[] = [];
	readonly mainMedia: MainMedia[] = [];
	readonly createdBy: any;
	readonly distributionRegions: ContentAttributes[] = [];
	readonly distributionChannels: ContentAttributes[] = [];
	readonly origin: ContentAttributes;
	readonly footer: string;
	readonly viewsCount: any;
	readonly commentsCount: any;
	readonly translationGroup: TranslationGroupModel;
	readonly properties: DynamicObjectWithKeys;
	readonly autoTaggingScope: ArticleAutoTaggingScope = {};
	readonly versions: VersionModel[] = [];
	readonly contentUpdatedAt: string;
	readonly isContentUpdatedAtSetAutomatically: boolean;
	readonly source: string;

	private constructor(
		id: string,
		title: string,
		subtitle: string,
		strapline: string,
		type: string,
		body: BlockModel[],
		publishedAt: string,
		createdAt: string,
		updatedAt: string,
		publishedUntil: string,
		status: string,
		comments: any,
		customAuthor: string,
		language: string,
		authors: Author[],
		image: any,
		generic: any,
		urls: Urls,
		seo: Seo,
		category: Category,
		additionalCategories: Category[],
		mainMedia: MainMedia[],
		createdBy: any,
		distributionRegions: ContentAttributes[],
		distributionChannels: ContentAttributes[],
		origin: ContentAttributes,
		footer: string,
		viewsCount: number,
		commentsCount: number,
		translationGroup: TranslationGroupModel,
		properties: DynamicObjectWithKeys,
		autoTaggingScope: ArticleAutoTaggingScope,
		versions: VersionModel[],
		contentUpdatedAt: string,
		isContentUpdatedAtSetAutomatically: boolean,
		source: string,
	) {
		this.id = id;
		this.title = title;
		this.subtitle = subtitle;
		this.strapline = strapline;
		this.type = type;
		this.body = body;
		this.publishedAt = publishedAt;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.publishedUntil = publishedUntil;
		this.status = status;
		this.comments = comments;
		this.customAuthor = customAuthor;
		this.language = language;
		this.authors = authors;
		this.image = image;
		this.generic = generic;
		this.urls = urls;
		this.seo = seo;
		this.category = category;
		this.additionalCategories = additionalCategories;
		this.mainMedia = mainMedia;
		this.createdBy = createdBy;
		this.distributionRegions = distributionRegions;
		this.distributionChannels = distributionChannels;
		this.origin = origin;
		this.footer = footer;
		this.viewsCount = viewsCount;
		this.commentsCount = commentsCount;
		this.translationGroup = translationGroup;
		this.properties = properties;
		this.autoTaggingScope = autoTaggingScope;
		this.versions = versions;
		this.contentUpdatedAt = contentUpdatedAt;
		this.isContentUpdatedAtSetAutomatically = isContentUpdatedAtSetAutomatically;
		this.source = source;
	}

	toJSON(): ArticleJson {
		return {
			id: this.id,
			title: this.title,
			subtitle: this.subtitle,
			strapline: this.strapline,
			type: this.type,
			body: this.body,
			publishedAt: this.publishedAt,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt,
			publishedUntil: this.publishedUntil,
			status: this.status,
			comments: this.comments,
			customAuthor: this.customAuthor,
			language: this.language,
			authors: this.authors,
			image: this.image,
			generic: this.generic,
			urls: this.urls,
			seo: this.seo,
			category: this.category,
			additionalCategories: this.additionalCategories,
			mainMedia: this.mainMedia,
			createdBy: this.createdBy,
			distributionRegions: this.distributionRegions,
			distributionChannels: this.distributionChannels,
			origin: this.origin,
			footer: this.footer,
			viewsCount: this.viewsCount,
			commentsCount: this.commentsCount,
			translationGroup: this.translationGroup,
			properties: this.properties,
			autoTaggingScope: this.autoTaggingScope,
			versions: this.versions,
			contentUpdatedAt: this.contentUpdatedAt,
			isContentUpdatedAtSetAutomatically: this.isContentUpdatedAtSetAutomatically,
			source: this.source,
		};
	}

	static fromJSON(json: ArticleJson): Article {
		return new Article(
			json.id,
			json.title,
			json.subtitle,
			json.strapline,
			json.type,
			json.body,
			json.publishedAt,
			json.createdAt,
			json.updatedAt,
			json.publishedUntil,
			json.status,
			json.comments,
			json.customAuthor,
			json.language,
			json.authors,
			json.image,
			json.generic,
			json.urls,
			json.seo,
			json.category,
			json.additionalCategories,
			json.mainMedia,
			json.createdBy,
			json.distributionRegions,
			json.distributionChannels,
			json.origin,
			json.footer,
			json.viewsCount,
			json.commentsCount,
			json.translationGroup,
			json.properties || {},
			json.autoTaggingScope,
			json.versions,
			json.contentUpdatedAt,
			json.isContentUpdatedAtSetAutomatically,
			json.source,
		);
	}

	static builder(article?: Article): ArticleBuilder {
		return new ArticleBuilder(article);
	}

	static extractDynamicPropertiesKeys(): string[] {
		const reduxStoreArticleProperties = store.getState().contentProperties.articleProperties;
		return reduxStoreArticleProperties.map((articleProperty: ContentAttributes) => articleProperty.slug);
	}

	static extractDynamicPropertiesKeysWithDescription(): DynamicObjectWithKeys[] {
		const reduxStoreArticleProperties = store.getState().contentProperties.articleProperties;
		return reduxStoreArticleProperties.map((articleProperty: ContentAttributes) => {
			return { slug: articleProperty.slug, description: articleProperty.name };
		});
	}

	static extractDynamicPropertiesAsObjectWithValues(): DynamicObjectWithKeys {
		const reduxStoreArticleProperties = store.getState().contentProperties.articleProperties || [];
		return reduxStoreArticleProperties.reduce(
			(obj: ContentAttributes, item: ContentAttributes) => Object.assign(obj, { [item.slug]: item.isDefault }),
			{},
		);
	}
}
