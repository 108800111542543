import { ContentTypes } from '../constants/content-types';
import Wiki from '../models/wiki/Wiki';
import UndoRedoBlockyModel from '../views/Partials/Blocky/models/UndoRedoBlockyMode';
import AdvancedFilterModel from '../views/Partials/Shared/advanced-content-filter/models/advanced-content-filter.model';
import { propertiesCompatibilityData } from './properties-compatibility.data';
import { defaultSportsForMigration, generateHardcodedDataBasedOnEnv } from './store-migrations.data';
import PaginationMeta from '../models/pagination/PaginationMeta';

export const migrations = {
	0: (state: any) => {
		return {
			...state,
			feed: {
				...state.feed,
				selectedFeed: {},
				notifications: [],
				selectedDate: {},
				sourceTab: {},
				selectedFilterSources: [],
			},
		};
	},
	1: (state: any) => {
		return {
			...state,
			project: {
				...state.project,
				currentProject: {
					...state.currentProject,
				},
			},
		};
	},
	2: (state: any) => {
		return {
			...state,
			profile: {
				...state.profile,
				sidebarSettings: null,
			},
		};
	},
	3: (state: any) => {
		return {
			...state,
			ui: {
				...state.ui,
				shouldShowListImages: false,
			},
		};
	},
	4: (state: any) => {
		return {
			...state,
			player: {
				...state.player,
				player: {},
				players: [],
				playerDeleted: false,
				pagination: {},
			},
		};
	},
	5: (state: any) => {
		return {
			...state,
			matchLineupsEdit: null,
		};
	},
	6: (state: any) => {
		return {
			...state,
			list: {
				...state.list,
				...state.lists,
				...state.pagination,
				...state.listDeleted,
				contentCreateList: null,
			},
		};
	},
	7: (state: any) => {
		return {
			...state,
			matchLineupsEdit: {
				...state.matchLineupsEdit,
				match: null,
				matches: [],
				teams: [],
				dateRange: [],
			},
		};
	},
	8: (state: any) => {
		return {
			...state,
			blocky: {
				...state.blocky,
				blockPlaceholder: false,
			},
		};
	},
	9: (state: any) => {
		return {
			...state,
			matchLineupsEdit: {
				...state.matchLineupsEdit,
				tournament: null,
				season: null,
				stage: null,
				round: null,
			},
		};
	},
	10: (state: any) => {
		delete state.matchLineupsEdit.tournament;
		delete state.matchLineupsEdit.season;
		delete state.matchLineupsEdit.stage;
		delete state.matchLineupsEdit.round;
		return {
			...state,
			matchLineupsEdit: {
				...state.matchLineupsEdit,
				filters: { tournament: null, season: null, stage: null, round: null },
			},
		};
	},
	11: (state: any) => {
		return {
			...state,
			quickAccess: {
				...state.quickAccess,
				quickAccessList: {},
			},
		};
	},
	12: (state: any) => {
		delete state.quickAccess;
		return {
			...state,
		};
	},
	13: (state: any) => {
		return {
			...state,
			blocky: {
				...state.blocky,
				blocksUndoRedo: new UndoRedoBlockyModel(),
			},
		};
	},
	14: (state: any) => {
		return {
			...state,
			blocky: {
				...state.blocky,
				blocksUndoRedo: new UndoRedoBlockyModel(),
			},
		};
	},
	15: (state: any) => {
		return {
			...state,
			tempWiki: {
				wiki: Wiki.builder().build(),
				wikiEdit: Wiki.builder().build(),
			},
			types: {
				...state.types,
				wikipageTypes: [],
			},
			statuses: {
				...state.statuses,
				wikipageStatuses: [],
			},
		};
	},
	16: (state: any) => {
		return {
			...state,
			contentProperties: propertiesCompatibilityData,
		};
	},
	17: (state: any) => {
		const projectId: string | null = (state && state.project && state.project.currentProject && state.project.currentProject.id) || null;

		if (projectId) {
			const sportTypesForMigration = generateHardcodedDataBasedOnEnv(projectId);

			return {
				...state,
				sports: {
					sportsTypes: sportTypesForMigration, // Set hardcoded sports depending on project id
				},
			};
		}

		return {
			...state,
			sports: {
				sportsTypes: defaultSportsForMigration, // Set default sports if project id is missing
			},
		};
	},
	18: (state: any) => {
		return {
			...state,
			video: {
				...state.video,
				jwFilters: AdvancedFilterModel.builder().build(),
			},
		};
	},
	19: (state: any) => {
		return {
			...state,
			ui: {
				...state.ui,
				contentLoadingState: {
					[ContentTypes.ARTICLE]: false,
					[ContentTypes.VIDEO]: false,
					[ContentTypes.GALLERY]: false,
					[ContentTypes.WIKI_PAGE]: false,
				},
			},
		};
	},
	20: (state: any) => {
		return {
			...state,
			multisportConfiguration: {
				previewData: [],
				modals: {
					removeEntity: {
						displayed: false,
						data: null,
					},
					save: {
						displayed: false,
						data: null,
					},
				},
				configurationDate: new Date(),
				eventSearchDate: new Date(),
				configurationCompetitionList: '',
			},
		};
	},
	21: (state: any) => {
		return {
			...state,
			ui: {
				...state.ui,
				previewBlockyId: '',
			},
		};
	},
	22: (state: any) => {
		return {
			...state,
			adminActivity: {
				...state,
				lastActivityTimestamp: null,
				externalArticlesCount: null,
				article: {
					...state.article,
					externalArticlesFilters: AdvancedFilterModel.builder().build(),
					externalArticlesPagination: PaginationMeta.builder().withCurrentPage(1).build(),
				},
			},
		};
	},
	23: (state: any) => {
		return {
			...state,
			contentProperties: propertiesCompatibilityData,
		};
	},
	24: (state: any) => {
		return {
			...state,
			contentSidebar: {
				tags: {
					domain: { name: 'Sport', value: 'sport', data: 'sport' },
					entityType: 'person',
					customEntities: {},
				},
			},
		};
	},
};
