import React, { ChangeEvent } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import BlockModel from '../../../../models/block.model';
import EntitySeasonSelectContainer from '../../../partials/player-select/player-season-select.component';
import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import TeamSelectComponent from '../../../partials/team-select/team-select.component';
import { ContentTypes } from '../../../../../../../constants/content-types';
import FootballTeamSquadWidgetModel from '../models/football-team-squad.model';
import GenericSelect from '../../../../partials/generic-select/generic-select-component';
import { teamSquadStatsOptions, WidgetTeamSquadDefaultHeader } from '../../football-team/constants/football-team.constants';
import { getDefaultType } from '../../../../blocks/content/embed-block/helpers/embed-block-edit.helper';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import { createBlock } from '../../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import { onRefreshTimeChangeFromMainComponent } from '../../../partials/refresh-time-input/helper';
import RefreshTimeWrapper from '../../../partials/refresh-time-input/refresh-time-wrapper';
import HeaderDisplayInput from '../../../partials/header-display-input/header-display-input.component';

import { ElementsSelect } from '../../../partials/elements-select/widget-elements-select';

type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: FootballTeamSquadWidgetModel;
	isValid: boolean;
};

export default class FootballTeamSquadEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetFootballTeamSquad,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			isValid: false,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { widgetModel: this.state.widgetModel };
		const isValid = validation.validate(this.props.block, data) === true;

		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: this.state.widgetModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateWidgetModelState = (model: FootballTeamSquadWidgetModel) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onTeamChange = (team: TeamModel) => {
		this.updateWidgetModelState(
			FootballTeamSquadWidgetModel.builder(this.state.widgetModel)
				.withTeam(team)
				.withTournamentSeason({} as EntitySeasonsSelectModel)
				.build(),
		);
	};

	onTournamentSeasonSelect = (season: EntitySeasonsSelectModel) => {
		this.updateWidgetModelState(FootballTeamSquadWidgetModel.builder(this.state.widgetModel).withTournamentSeason(season).build());
	};

	onDefaultHeaderChange = (view: any) => {
		this.updateWidgetModelState(FootballTeamSquadWidgetModel.builder(this.state.widgetModel).withHeaderDefaultOption(view).build());
	};

	onElementsChange = (elements: string[]) => {
		this.updateWidgetModelState(FootballTeamSquadWidgetModel.builder(this.state.widgetModel).withElements(elements).build());
	};

	onDisplayHeaderChange = (e: ChangeEvent<HTMLInputElement>) => {
		this.updateWidgetModelState(FootballTeamSquadWidgetModel.builder(this.state.widgetModel).withDisplayHeader(e.target.checked).build());
	};

	render() {
		const { widgetModel, isValid } = this.state;
		const { t } = this.props;

		return (
			<>
				<div data-qa='football-team-squad-block-edit'>
					<Row>
						<Col>
							<FormGroup>
								<TeamSelectComponent
									isMulti={false}
									isBlocky
									isValid={isValid}
									selectedTeam={widgetModel.team ? widgetModel.team : {}}
									onTeamSelect={(team: TeamModel) => this.onTeamChange(team)}
									t={t}
									isClearable
									isRequired
									searchByKeyword={true}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TEAM_SELECT}`}
								/>
							</FormGroup>
						</Col>
					</Row>
					{widgetModel.team && widgetModel.team.id && widgetModel.team.id.toString().length > 0 && (
						<Row>
							<Col>
								<FormGroup>
									<EntitySeasonSelectContainer
										isClearable
										isRequired={false}
										isBlocky
										entityId={widgetModel.team.id}
										selectedSeason={widgetModel.tournamentSeason}
										onEntitySeasonSelect={this.onTournamentSeasonSelect}
										isValid={isValid}
										entityType={ContentTypes.TEAM}
										id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TEAM_SEASON_SELECT}`}
									/>
								</FormGroup>
							</Col>
						</Row>
					)}
					{widgetModel.team && widgetModel.team.id && (
						<Row className='mt-2' data-qa={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.GENERIC_SELECT}`}>
							<Col>
								<FormGroup>
									<Label>{t('select_view')}</Label>
									<GenericSelect
										t={t}
										isMulti={false}
										options={widgetModel.team && widgetModel.team.id && WidgetTeamSquadDefaultHeader}
										value={
											widgetModel.headerDefaultOption
												? widgetModel.headerDefaultOption
												: this.onDefaultHeaderChange(getDefaultType(WidgetTeamSquadDefaultHeader, t))
										}
										onGenericOptionSelect={this.onDefaultHeaderChange}
									/>
								</FormGroup>
							</Col>
						</Row>
					)}
					{widgetModel.team && widgetModel.team.id && (
						<Row className='mt-2' data-qa='football-team-squad-elements-select'>
							<Col>
								<FormGroup>
									<Label htmlFor={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.ELEMENTS}`}>{t('select_stats')}</Label>
									<ElementsSelect value={widgetModel.elements} onSelect={this.onElementsChange} t={t} isMulti elements={teamSquadStatsOptions} />
								</FormGroup>
							</Col>
						</Row>
					)}
					<Row className='mt-2'>
						<Col>
							<HeaderDisplayInput onDisplayHeaderChange={this.onDisplayHeaderChange} value={widgetModel.displayHeader} />
						</Col>
					</Row>
				</div>
			</>
		);
	}
}
