import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppState } from '../../../../../../store/store';
import { useSelector } from 'react-redux';
import { Col, Label, Row } from 'reactstrap';
import Select from 'react-select';
import { customOption } from '../../../partials/shared/custom-select-option';
import { ISelectOption } from '../../types/player';
import HttpService from '../../../../../../services/rest/HttpService';
import EntitySeasonsSelectModel from '../../../partials/player-seasons/model/entity-seasons-select.model';
import {
	createTournamentGroupOptions,
	mapResponseListToTeamSeasonsModel,
	teamSeasonToOption,
} from './helpers/team-season-statistics-select.helper';
import { SeasonsResponseData, Seasons } from '../../types/seasons';
interface Props {
	id: string;
	team: string;
	selectedSeason: EntitySeasonsSelectModel;
	onSeasonSelect: (season: EntitySeasonsSelectModel | null) => void;
}

const TeamSeasonStatisticsSelect: FC<Props> = (props) => {
	const [t] = useTranslation();
	const lang = useSelector((state: AppState) => state.project.currentProject.language);

	const { id, team, selectedSeason, onSeasonSelect } = props;
	const [options, setOptions] = useState<Seasons[]>();

	const updateEntitySeasonsOptionsState = (seasons: Seasons[]) => {
		if (!selectedSeason || !selectedSeason.seasonId) {
			onSeasonSelect(seasons[0].options[0].data);
		}
		setOptions(seasons);
	};

	useEffect(() => {
		setOptions([]);
		if (team) {
			HttpService.getTeamSeasons(team, lang)
				.then((response: SeasonsResponseData) => {
					const modelList = mapResponseListToTeamSeasonsModel(response.data.seasons);
					const options = createTournamentGroupOptions(modelList);
					updateEntitySeasonsOptionsState(options);
				})
				.catch((e: unknown) => e);
		}
	}, [team]);

	const onChange = (option: ISelectOption<EntitySeasonsSelectModel> | null) => {
		if (option) {
			onSeasonSelect(option.data);
			return;
		}
		onSeasonSelect({} as EntitySeasonsSelectModel);
	};

	return (
		<Row data-qa={id}>
			<Col>
				<Label htmlFor={id}>{t('tournament_seasons')}:</Label>
				<Select
					id={id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					formatOptionLabel={customOption}
					options={options}
					value={selectedSeason && selectedSeason.seasonId && teamSeasonToOption(selectedSeason)}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					isClearable
					placeholder={t('select')}
					onChange={(selection) => onChange(selection as ISelectOption<EntitySeasonsSelectModel> | null)}
				/>
			</Col>
		</Row>
	);
};

export default TeamSeasonStatisticsSelect;
