import Seo from '../seo/Seo';
import Urls from '../urls/Urls';
import { CategoryJson } from './CategoryJson';
import CategoryBuilder from './CategoryBuilder';
import MainMedia from '../mainmedia/MainMedia';
import { store } from '../../store/store';
import ContentAttributes from '../content-attributes/content-attributes-model';
import { DynamicObjectWithKeys } from '../../constants/content-types';

export default class Category {
	readonly id: string;
	readonly title: string;
	readonly description: string;
	readonly active: boolean;
	readonly parentId: string;
	readonly generic: string;
	readonly seo: Seo;
	readonly urls: Urls;
	readonly mainMedia: MainMedia[] = [];
	readonly createdAt: string;
	readonly updatedAt: string;
	readonly subIndent: string;
	readonly properties: DynamicObjectWithKeys;

	private constructor(
		id: string,
		title: string,
		description: string,
		active: boolean,
		parentId: string,
		generic: string,
		seo: Seo,
		urls: Urls,
		createdAt: string,
		updatedAt: string,
		subIndent: string,
		mainMedia: MainMedia[],
		properties: DynamicObjectWithKeys,
	) {
		this.id = id;
		this.title = title;
		this.description = description;
		this.active = active;
		this.parentId = parentId;
		this.generic = generic;
		this.seo = seo;
		this.urls = urls;
		this.mainMedia = mainMedia;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.subIndent = subIndent;
		this.properties = properties;
	}

	toJSON(): CategoryJson {
		return {
			id: this.id,
			title: this.title,
			description: this.description,
			active: this.active,
			parentId: this.parentId,
			generic: this.generic,
			seo: this.seo,
			urls: this.urls,
			subIndent: this.subIndent,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt,
			mainMedia: this.mainMedia,
			properties: this.properties,
		};
	}

	static fromJSON(json: CategoryJson): Category {
		return new Category(
			json.id,
			json.title,
			json.description,
			json.active,
			json.parentId,
			json.generic,
			json.seo,
			json.urls,
			json.createdAt,
			json.updatedAt,
			json.subIndent,
			json.mainMedia,
			json.properties || {},
		);
	}

	static builder(category?: Category): CategoryBuilder {
		return new CategoryBuilder(category);
	}

	static extractDynamicPropertiesKeys(): string[] {
		const reduxStoreCategoryProperties = store.getState().contentProperties.categoryProperties;
		return reduxStoreCategoryProperties.map((categoryProperty: ContentAttributes) => categoryProperty.slug);
	}

	static extractDynamicPropertiesKeysWithDescription(): DynamicObjectWithKeys[] {
		const reduxStoreCategoryProperties = store.getState().contentProperties.categoryProperties;
		return reduxStoreCategoryProperties.map((categoryProperty: ContentAttributes) => {
			return { slug: categoryProperty.slug, description: categoryProperty.name };
		});
	}

	static extractDynamicPropertiesAsObjectWithValues(): DynamicObjectWithKeys {
		const reduxStoreCategoryProperties = store.getState().contentProperties.categoryProperties || [];
		return reduxStoreCategoryProperties.reduce(
			(obj: ContentAttributes, item: ContentAttributes) => Object.assign(obj, { [item.slug]: item.isDefault }),
			{},
		);
	}
}
