import React from 'react';
import { Col, Row } from 'reactstrap';
import FootballTeamSquadWidgetModel from '../models/football-team-squad.model';
import { generateDisplayHeaderOptionViewText } from '../../../partials/header-display-input/helper';
import { teamSquadStatsOptions } from '../../football-team/constants/football-team.constants';

type Properties = {
	preview: FootballTeamSquadWidgetModel;
	t: any;
};

export const FootballTeamSquadView: React.FunctionComponent<Properties> = ({ preview, t }) => {
	const stats = preview.elements && preview.elements.length > 0 ? preview.elements : teamSquadStatsOptions;

	if (preview && preview.team && preview.team.id) {
		return (
			<>
				<Row className='px-4' data-qa='football-team--squad-block-view'>
					<Col className='text-left'>
						<Row className='mb-2'>
							<Col>
								{preview.team && preview.team.id && (
									<div className='single-row'>
										<strong>{t('team')}:</strong> {preview.team.name}
									</div>
								)}
								{preview.tournamentSeason && preview.tournamentSeason.tournamentName && (
									<div className='single-row'>
										<strong>{t('tournament')}:</strong> {preview.tournamentSeason.tournamentName}
									</div>
								)}
								{preview.tournamentSeason && preview.tournamentSeason.seasonName && (
									<div className='single-row'>
										<strong>{t('season')}:</strong> {preview.tournamentSeason.seasonName}
									</div>
								)}
							</Col>
						</Row>
						<Row className='single-row'>
							<Col>
								<strong>{t('select_view')}:</strong>
								<span>{preview.headerDefaultOption && preview.headerDefaultOption.label && ` ${preview.headerDefaultOption.label}`}</span>
							</Col>
						</Row>
						<hr />
						{stats && stats.length > 0 && (
							<Row className='single-row'>
								<Col>
									<strong>{t('selected_stats')}:</strong>
									{stats.map((stat: string, index: number) => {
										return (
											<span key={`team-squad-stat-key-${index}`} className='pl-1'>
												{t(`${stat}_title`)}
												{index + 1 === stats.length ? '' : ','}
											</span>
										);
									})}
								</Col>
							</Row>
						)}
						<hr />
					</Col>
				</Row>
				{generateDisplayHeaderOptionViewText(preview.displayHeader)}
			</>
		);
	}

	return null;
};
