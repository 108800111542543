import React from 'react';
import { Col, Row } from 'reactstrap';
import FootballTeamWidgetModel from '../models/football-team-widget.model';
import { imagePlaceHolder } from '../../../../../../../constants/resource-constants';
import { generateRefreshTimeOptionViewText } from '../../../partials/refresh-time-input/helper';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import { useTranslation } from 'react-i18next';

type Properties = {
	preview: FootballTeamWidgetModel;
};

export const FootballTeamView: React.FunctionComponent<Properties> = ({ preview }) => {
	const [t] = useTranslation();

	if (preview && preview.team && preview.team.id) {
		const hasValidMatchData =
			preview.match &&
			preview.match.homeTeam &&
			preview.match.homeTeam.name &&
			preview.match.awayTeam &&
			preview.match.awayTeam.name &&
			preview.match.status &&
			preview.match.status.name;

		return (
			<>
				<Row className='px-4' data-qa='football-team-block-view'>
					<Col className='text-left'>
						<Row className='mb-2'>
							<Col>
								<div className='single-row'>
									<strong>{t('team')}:</strong> {preview.team.name}
								</div>
								{preview.tournamentSeason && preview.tournamentSeason.tournamentName && (
									<div className='single-row'>
										<strong>{t('tournament')}:</strong> {preview.tournamentSeason.tournamentName}
									</div>
								)}
								{preview.tournamentSeason && preview.tournamentSeason.seasonName && (
									<div className='single-row'>
										<strong>{t('season')}:</strong> {preview.tournamentSeason.seasonName}
									</div>
								)}
								{preview.match && preview.match.id && (
									<div className='single-row'>
										<strong>{t('match')}:</strong>{' '}
										{hasValidMatchData && `${preview.match.homeTeam.name} - ${preview.match.awayTeam.name} (${preview.match.status.name})`}
									</div>
								)}
							</Col>
						</Row>
						<hr />
						<Row className='mt-2 mb-2'>
							{preview.statsParameters && preview.statsParameters.length > 0 && (
								<Col>
									<strong>{t('selected_stats')}:</strong>
									{preview.statsParameters.map((info, index) => {
										return (
											<span key={`team-profile-stats-parameters-key-${index}`} className='pl-1'>
												{t(`${info}_title`)}
												{index + 1 === preview.statsParameters.length ? '' : ','}
											</span>
										);
									})}
								</Col>
							)}
						</Row>
						<Row className='mt-2 mb-2'>
							<Col>
								<strong>{t('display_social_icons_label')}:</strong>
								<strong>
									<span className={preview.displaySocialIcons ? 'text-success' : 'text-danger'}>
										{preview.displaySocialIcons ? ` ${t('yes')}` : ` ${t('no')}`}
									</span>
								</strong>
							</Col>
						</Row>
						<Row className='mt-2 mb-2'>
							<Col>
								<strong>{t('display_team_image_label')}:</strong>
								<strong>
									<span className={preview.displayTeamImage ? 'text-success' : 'text-danger'}>
										{preview.displayTeamImage ? ` ${t('yes')}` : ` ${t('no')}`}
									</span>
								</strong>
							</Col>
						</Row>
						<Row>
							<Col className='text-left'>
								<Row>
									<Col>
										<strong>{t('display_odds')}:</strong>
										<strong>
											<span className={preview.displayOdds ? 'text-success' : 'text-danger'}>
												{preview.displayOdds ? ` ${t('yes')}` : ` ${t('no')}`}
											</span>
										</strong>
									</Col>
								</Row>
								{preview.displayOdds && preview.bookmakers && preview.bookmakers.length > 0 && (
									<Row className='mt-2'>
										<Col className='text-left'>
											<strong>{t('show')}:</strong>
											{preview.bookmakers.map((bookmaker: SportOddsBookmakerModel) => {
												return (
													<img
														key={bookmaker.id}
														height='20'
														alt={bookmaker.name}
														className='h-20 mx-1'
														src={bookmaker.assets && bookmaker.assets[0] && bookmaker.assets[0].logo ? bookmaker.assets[0].logo : imagePlaceHolder}
														title={bookmaker.name}
													/>
												);
											})}
										</Col>
									</Row>
								)}
							</Col>
						</Row>
					</Col>
				</Row>
				{generateRefreshTimeOptionViewText(preview.refreshTime)}
			</>
		);
	}

	return null;
};
