import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import { FootballTeamWidgetJson } from './football-team.json';
import FootballTeamWidgetBuilder from './football-team.builder';
import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import PlayerOrTeamMatchModel from '../../../partials/match-select/models/player-or-team-match.model';

export default class FootballTeamWidgetModel {
	readonly team: TeamModel;
	readonly tournamentSeason: EntitySeasonsSelectModel;
	readonly displayMatch: boolean = true;
	readonly match: PlayerOrTeamMatchModel;
	readonly displayOdds: boolean = false;
	readonly bookmakers: SportOddsBookmakerModel[] | null;
	readonly displayStatsParameters: boolean = true;
	readonly statsParameters: string[];
	readonly displaySocialIcons: boolean = true;
	readonly displayTeamImage: boolean = true;
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(
		team: TeamModel,
		tournamentSeason: EntitySeasonsSelectModel,
		displayMatch: boolean,
		match: PlayerOrTeamMatchModel,
		displayOdds: boolean,
		bookmakers: SportOddsBookmakerModel[] | null,
		displayStatsParameters: boolean,
		statsParameters: string[],
		displaySocialIcons: boolean,
		displayTeamImage: boolean,
		refreshTime: RefreshTimeValuesType,
	) {
		this.team = team;
		this.tournamentSeason = tournamentSeason;
		this.displayMatch = displayMatch;
		this.match = match;
		this.displayOdds = displayOdds;
		this.bookmakers = bookmakers;
		this.displayStatsParameters = displayStatsParameters;
		this.statsParameters = statsParameters;
		this.displaySocialIcons = displaySocialIcons;
		this.displayTeamImage = displayTeamImage;
		this.refreshTime = refreshTime;
	}

	toJson(): FootballTeamWidgetJson {
		return {
			team: this.team,
			tournamentSeason: this.tournamentSeason,
			displayMatch: this.displayMatch,
			match: this.match,
			displayOdds: this.displayOdds,
			bookmakers: this.bookmakers,
			displayStatsParameters: this.displayStatsParameters,
			statsParameters: this.statsParameters,
			displaySocialIcons: this.displaySocialIcons,
			displayTeamImage: this.displayTeamImage,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: FootballTeamWidgetJson): FootballTeamWidgetModel {
		return new FootballTeamWidgetModel(
			json.team,
			json.tournamentSeason,
			json.displayMatch,
			json.match,
			json.displayOdds,
			json.bookmakers,
			json.displayStatsParameters,
			json.statsParameters,
			json.displaySocialIcons,
			json.displayTeamImage,
			json.refreshTime,
		);
	}

	static builder(model?: FootballTeamWidgetModel): FootballTeamWidgetBuilder {
		return new FootballTeamWidgetBuilder(model);
	}
}
