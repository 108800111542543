import React from 'react';
import moment from 'moment';
import LivescoreWidgetModel from '../../../../blocks/widgets/livescore/models/livescore-widget.model';
import { Col, Row } from 'reactstrap';
import { generateHeaderDefaultOptionViewText } from '../../../partials/header-default-option-input/helper';
import { generateDisplayHeaderOptionViewText } from '../../../partials/header-display-input/helper';
import { generateRefreshTimeOptionViewText } from '../../../partials/refresh-time-input/helper';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import { imagePlaceHolder } from '../../../../../../../constants/resource-constants';
import { useTranslation } from 'react-i18next';
import { usePreviewCompetitions } from '../../helpers/hooks/usePreviewCompetitions';

type Properties = {
	livescorePreview: LivescoreWidgetModel;
};

export const IceHockeyLivescoreWidgetView: React.FunctionComponent<Properties> = ({ livescorePreview }) => {
	const [t] = useTranslation();

	const { competitions, showAllCompetitions, isLoading } = usePreviewCompetitions({ competitionIds: livescorePreview.competitionIds });

	if (livescorePreview && livescorePreview.date && livescorePreview.date !== '' && !isLoading) {
		const formattedDate = moment(livescorePreview.date).format('YYYY.MM.DD');
		return (
			<>
				<Row className='pb-2 pl-4 pr-4' data-qa='ice-hockey-livescore-view'>
					<Col className='text-center'>
						<Row>
							<Col className='text-left'>
								{t('selected_date')}:
								<strong>
									<span>{` ${formattedDate}`}</span>
								</strong>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className='pl-4 pr-4 pb-2'>
					<Col className='text-center'>
						<Row>
							<Col className='text-left'>
								{t('livescore_preview.competitions_label')}:
								{showAllCompetitions ? (
									<strong>
										<span> {t('livescore_preview.all_competitions_value')}</span>
									</strong>
								) : (
									<div>
										{competitions.map((comp) => (
											<div>
												<strong>
													<span>[{comp.name}] </span>
												</strong>
												<span>{comp.competitions.map(({ label }) => `${label}, `)}</span>
											</div>
										))}
									</div>
								)}
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className='pl-4 pr-4 pb-2'>
					<Col className='text-center'>
						<Row>
							<Col className='text-left'>
								{t('display_odds')}:
								<strong>
									<span className={livescorePreview.displayOdds ? 'text-success' : 'text-danger'}>
										{livescorePreview.displayOdds ? ` ${t('yes')}` : ` ${t('no')}`}
									</span>
								</strong>
							</Col>
						</Row>
					</Col>
				</Row>
				{livescorePreview.displayOdds && livescorePreview.bookmakers && livescorePreview.bookmakers.length > 0 && (
					<Row className='pb-4 pl-4 pr-4'>
						<Col>
							{t('show')}:
							{livescorePreview.bookmakers.map((bookmaker: SportOddsBookmakerModel) => {
								return (
									<img
										key={bookmaker.id}
										height='20'
										alt={bookmaker.name}
										className='h-20 mx-1'
										src={bookmaker.assets && bookmaker.assets[0] && bookmaker.assets[0].logo ? bookmaker.assets[0].logo : imagePlaceHolder}
										title={bookmaker.name}
									/>
								);
							})}
						</Col>
					</Row>
				)}
				{generateHeaderDefaultOptionViewText(livescorePreview.headerDefaultOption)}
				{generateDisplayHeaderOptionViewText(livescorePreview.displayHeader)}
				{generateRefreshTimeOptionViewText(livescorePreview.refreshTime)}
			</>
		);
	}

	return null;
};
