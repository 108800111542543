import FootballTeamWidgetModel from './football-team-widget.model';
import { FootballTeamWidgetJson } from './football-team.json';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import PlayerOrTeamMatchModel from '../../../partials/match-select/models/player-or-team-match.model';

export default class FootballTeamWidgetBuilder {
	readonly json: FootballTeamWidgetJson;

	constructor(team?: FootballTeamWidgetModel | FootballTeamWidgetJson) {
		if (team && team instanceof FootballTeamWidgetModel) {
			this.json = team.toJson();
		} else if (team) {
			this.json = team;
		} else {
			this.json = {} as FootballTeamWidgetJson;
		}
	}

	withTeam(team: TeamModel): FootballTeamWidgetBuilder {
		this.json.team = team;

		return this;
	}

	withTournamentSeason = (tournamentSeason: EntitySeasonsSelectModel): FootballTeamWidgetBuilder => {
		this.json.tournamentSeason = tournamentSeason;

		return this;
	};

	withDisplayedMatch = (displayMatch: boolean): FootballTeamWidgetBuilder => {
		this.json.displayMatch = displayMatch;

		return this;
	};

	withMatch(match: PlayerOrTeamMatchModel): FootballTeamWidgetBuilder {
		this.json.match = match;

		return this;
	}

	withDisplayOdds(displayOdds: boolean): FootballTeamWidgetBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withBookmakers = (bookmakers: SportOddsBookmakerModel[] | null): FootballTeamWidgetBuilder => {
		this.json.bookmakers = bookmakers;

		return this;
	};

	withDisplayedStatsParameters = (displayStatsParameters: boolean): FootballTeamWidgetBuilder => {
		this.json.displayStatsParameters = displayStatsParameters;

		return this;
	};

	withStatsParameters(statsParameters: string[]): FootballTeamWidgetBuilder {
		this.json.statsParameters = statsParameters;

		return this;
	}

	withSocialIcons(displaySocialIcons: boolean): FootballTeamWidgetBuilder {
		this.json.displaySocialIcons = displaySocialIcons;

		return this;
	}

	withTeamImage(displayTeamImage: boolean): FootballTeamWidgetBuilder {
		this.json.displayTeamImage = displayTeamImage;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): FootballTeamWidgetBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): FootballTeamWidgetModel {
		return FootballTeamWidgetModel.fromJson(this.json);
	}
}
