import React from 'react';
import { Col, Row } from 'reactstrap';
import FootballTopScorersModel from '../models/football-top-scorers.model';
import { generateRefreshTimeOptionViewText } from '../../../partials/refresh-time-input/helper';

type Properties = {
	preview: FootballTopScorersModel;
	t: any;
};

export const FootballTopScorersView: React.FunctionComponent<Properties> = ({ preview, t }) => {
	if (preview && preview.tournament && preview.tournament.id) {
		return (
			<>
				<Row className='px-4 ' data-qa='football-top-scorers-block-view'>
					<Col>
						<Row className='mb-2 text-left'>
							<Col>
								<div className='single-row'>
									<strong>{t('tournament')}:</strong> {preview.tournament.name}
									<img
										src={preview.tournament.urlLogo ? preview.tournament.urlLogo : ''}
										height='35'
										className='mx-1'
										alt={`${preview.tournament && preview.tournament.name}`}
									/>
								</div>
								{preview.season && preview.season.id && (
									<div className='single-row'>
										<strong>{t('season')}:</strong> {preview.season.name}
									</div>
								)}
								<Row className='text-left'>
									<Col>
										<strong>{t('teams')}:</strong>
										{preview.teams && preview.teams.length > 0 ? (
											preview.teams.map((team, index) => {
												return (
													<span key={`teams-top-scorer-key-${index}`}>
														<img className='img-circle ml-2' width='20px' src={team.urlLogo} alt={team.name} /> {team.name}
														{index + 1 === preview.teams.length ? '' : ','}
													</span>
												);
											})
										) : (
											<span> {t('no_teams_selected')}</span>
										)}
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className='text-left'>
							<Col>
								<strong>{t('highlighted_players')}:</strong>
								{preview.topScorers && preview.topScorers.length > 0 ? (
									preview.topScorers.map((player, index) => {
										return (
											<span key={`player-top-scorer-key-${index}`}>
												<img className='img-circle ml-2' width='20px' src={player.urlThumb} alt={player.name} /> {player.name}
												{index + 1 === preview.topScorers.length ? '' : ','}
											</span>
										);
									})
								) : (
									<span> {t('no_players_selected')}</span>
								)}
							</Col>
						</Row>
						<Row>
							{preview.startFrom && preview.startFrom.length > 0 && (
								<Col>
									<strong>{t('start_from_position')}:</strong>
									<span className='ml-2'>{preview.startFrom}</span>
								</Col>
							)}
						</Row>
						<Row className='mb-2'>
							{preview.limit && preview.limit.length > 0 && (
								<Col>
									<strong>{t('show_number_of_players')}:</strong>
									<span className='ml-2'>{preview.limit}</span>
								</Col>
							)}
						</Row>
						<Row className='mb-2 text-left'>
							{preview.stats && preview.stats.length > 0 && (
								<Col>
									<strong>{t('selected_stats')}:</strong>
									{preview.stats.map((stat, index) => {
										return (
											<span key={`top-scorers-stat-key-${index}`} className='pl-1'>
												{t(`${stat}_title`)}
												{index + 1 === preview.stats.length ? '' : ','}
											</span>
										);
									})}
								</Col>
							)}
						</Row>
					</Col>
				</Row>
				{generateRefreshTimeOptionViewText(preview.refreshTime)}
			</>
		);
	}

	return null;
};
