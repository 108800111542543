import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import HttpService from '../../../../../../services/rest/HttpService';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store/store';
import { IMatchesResponse, ISelectOption } from '../../types/player';
import { matchOption, matchesOptions, remapMatchesResponse } from './helpers/match-select.helper';
import PlayerOrTeamMatchModel from './models/player-or-team-match.model';

type Properties = {
	id: string;
	teamId: string;
	selectedMatch: PlayerOrTeamMatchModel;
	onMatchSelect: (match: PlayerOrTeamMatchModel) => void;
};

const TeamMatchesSelect: React.FunctionComponent<Properties> = (props) => {
	const [t] = useTranslation();
	const lang = useSelector((state: AppState) => state.project.currentProject.language);
	
	const {id, teamId, selectedMatch, onMatchSelect} = props;
	const [options, setOptions] = useState<ISelectOption<PlayerOrTeamMatchModel>[]>([]);

	useEffect(() => {
		setOptions([])
		if(teamId){
			getMatchesByTeam()
		}
	}, [teamId]);

	const updateMatchesOptionsState = (matches: ISelectOption<PlayerOrTeamMatchModel>[]) => {
		if (!selectedMatch || !selectedMatch.id) {
			onMatchSelect(matches[0].data);
		}

		setOptions(matches);
	};

	const getMatchesByTeam = () => {
		HttpService.getMatchesByTeamId(teamId, lang)
			.then((response: IMatchesResponse) => {
				const matches = remapMatchesResponse(response.data.matches);
				const options = matchesOptions(matches);
				updateMatchesOptionsState(options);
			})
			.catch((e: unknown) => e);
	};

	const onChange = (option: ISelectOption<PlayerOrTeamMatchModel> | null) => {
		if (option) {
			onMatchSelect(option.data);
			return;
		}
		onMatchSelect({} as PlayerOrTeamMatchModel);
	};

	return (
		<Row data-qa={id}>
			<Col>
				<Label htmlFor={id}>{t('match')}:</Label>
				<Select
					id={id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					options={options}
					value={selectedMatch && selectedMatch.id ? matchOption(selectedMatch) : null}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					isClearable
					placeholder={t('select')}
					onChange={(selection) => onChange(selection as ISelectOption<PlayerOrTeamMatchModel> | null)}
				/>
			</Col>
		</Row>
	);
};

export default TeamMatchesSelect as React.ComponentType<Properties>;
