import 'reflect-metadata';
import { jsonMember, jsonObject, jsonArrayMember, AnyT } from 'typedjson';
import Country from '../Country/Country.model';

@jsonObject()
export default class FootballConnectionResponseModel {
	@jsonMember(Number)
	public id: number | null = null;
	@jsonMember(String)
	public uuid: string = '';
	@jsonMember(String)
	public name: string | null = null;
	@jsonMember(String)
	public url_image?: string;
	@jsonMember(String)
	public url_logo?: string;
	@jsonMember(String)
	public url_thumb?: string;
	@jsonMember(String)
	public gender?: string;
	@jsonMember(String)
	public entity_type: string | null = null;
	@jsonArrayMember(AnyT)
	public origin_ids: (string | number)[] = []; // we use this property only for suggested entities
	@jsonMember(Boolean)
	public hide_from_suggested?: boolean; // we use this property only for suggested entities
	@jsonMember(String)
	public slug: string | null = null;
	@jsonMember(Country)
	public country: Country | null = null;
}
