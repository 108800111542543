import React from 'react';
import { ReactPaginateProps } from 'react-paginate';
import { PAGINATE_PROPS } from '../../../Partials/BaseComponents/ListingComponent/subcomponents/AuthorizedListing/properties/PaginateProps';
import { CustomEntitiesSearchFiltersModel, IDomain, IPersonRole, ITelephone, Meta } from '../models/models';
import i18next from 'i18next';
import InputDesign from '../../../Partials/design-components/input/input';
import SelectInputDesign from '../../../Partials/design-components/select/select';
import DynamicSelectInputDesign from '../../../Partials/design-components/dynamic-select/dynamic-select';
import { GenderEnum } from '../../Tournaments/constants/Contstants';
import { extractContentModeBasedOnUrl } from '../../../../global-helpers/global.helpers';
import { store } from '../../../../store/store';
import CustomEntitiesHttpService from '../../../../services/rest/custom-entities.http.service';
import SearchDateDesign from '../../../Partials/design-components/search-date/search-date';
import { formatGeneralDateAsString, isDateObjValid } from '../../../../global-helpers/global-dates.helper';
import '../styles/custom-entities-listing.style.scss';
import StylesConfig from 'react-select';
import { MultiValueGenericProps } from 'react-select/src/components/MultiValue';
import { ImageTypes } from '../components/details/subcomponents/dnd-images';
import PhoneInputDesign from '../../../Partials/design-components/phone/phone';
import '../../CustomEntities/components/details/details.scss';
import HttpService from '../../../../services/rest/HttpService';
import { multiLingualService } from '../../../../App';
import Wiki from '../../../../models/wiki/Wiki';
import i18n from '../../../../i18n';

export const LIMIT = 20;

export const returnListingQueryParameter = (parameters: string[]) => {
	let queryParameters: string[] = [];

	if (parameters && parameters.length > 0) {
		queryParameters = parameters.map((parameter: string) => parameter);
	}

	return queryParameters;
};

export const CUSTOM_ENTITIES_ENTITY_TYPES_OPTIONS = [
	{ value: 'organization', label: 'organization', data: 'organization' },
	{ value: 'place', label: 'place', data: 'place' },
	{ value: 'person', label: 'person', data: 'person' },
	{ value: 'role', label: 'role', data: 'role' },
	{ value: 'domain', label: 'domain', data: 'domain' },
];

export const CUSTOM_ENTITIES_ENTITY_TYPES_DEFAULT_OPTIONS = ['organization', 'place', 'person', 'role', 'domain'];

export const selectEntityTypesOptions = (values: string[]) => {
	return (
		values &&
		values.map((value: string) => {
			switch (value) {
				case CustomEntitiesTypes.ORGANIZATION: {
					return CUSTOM_ENTITIES_ENTITY_TYPES_OPTIONS[0];
				}
				case CustomEntitiesTypes.PLACE: {
					return CUSTOM_ENTITIES_ENTITY_TYPES_OPTIONS[1];
				}
				case CustomEntitiesTypes.PERSON: {
					return CUSTOM_ENTITIES_ENTITY_TYPES_OPTIONS[2];
				}
				case CustomEntitiesTypes.ROLE: {
					return CUSTOM_ENTITIES_ENTITY_TYPES_OPTIONS[3];
				}
				case CustomEntitiesTypes.DOMAIN: {
					return CUSTOM_ENTITIES_ENTITY_TYPES_OPTIONS[4];
				}
				default: {
					return null;
				}
			}
		})
	);
};

export const customEntitiesPaginateProps = (
	contentPagination: Meta,
	handlePageClick: (selectedItem: { selected: number }) => void,
): ReactPaginateProps => {
	const totalPages = Math.ceil(contentPagination.total / LIMIT);

	return {
		...PAGINATE_PROPS,
		previousLabel: i18next.t('previous_page'),
		nextLabel: i18next.t('next_page'),
		pageCount: totalPages,
		onPageChange: handlePageClick,
		marginPagesDisplayed: 2,
		pageRangeDisplayed: 1,
		containerClassName: 'custom-entities-pagination',
		forcePage: contentPagination.offset / 20,
	};
};

export const constructCustomEntitiesSearchContentQuery = (data: CustomEntitiesSearchFiltersModel) => {
	if (data) {
		const entityTypeQuery =
			data.entity_types && data.entity_types.length > 0 ? `&entity_type=${returnListingQueryParameter(data.entity_types)}` : '';
		const domainQuery = data.domain && data.domain.id && data.domain.id.length > 0 ? `&domain=${data.domain.slug}` : '';

		return entityTypeQuery + domainQuery;
	}

	return '';
};

export const checkIfCustomEntitiesFiltersAreEmpty = (model: CustomEntitiesSearchFiltersModel) => {
	if (model && Object.entries(model).length > 0) {
		const isEntityTypesEmpty = !model.entity_types || (model.entity_types && model.entity_types.length === 0);
		const isDomainsEmpty = !model.domain || (model.domain && model.domain.id && model.domain.id.length === 0);

		return isEntityTypesEmpty && isDomainsEmpty;
	}

	return true;
};

export const extractCustomEntitiesSearchQueryFromFilters = (filters: CustomEntitiesSearchFiltersModel) => {
	let query: string = '';

	if (filters && Object.entries(filters).length > 0) {
		const searchText = filters.search_text && filters.search_text.length > 0 ? `name=${filters.search_text}` : '';
		const advancedContentSearchText = constructCustomEntitiesSearchContentQuery(filters);

		query = searchText + advancedContentSearchText;
	}

	return query;
};

export const domainsToOptions = (domains: IDomain[]) => {
	if (domains && domains.length > 0) {
		return domains.map((domain) => {
			return domainToOption(domain);
		});
	}
	return [];
};

export const domainToOption = (domain: IDomain | null) => {
	if (domain) {
		return {
			value: domain.id,
			label: domain.name,
			data: domain,
			type: 'domain',
		};
	} else {
		return null;
	}
};

enum InputFieldType {
	TEXT = 'text',
	DATE = 'date',
	SELECT = 'select',
	DYNAMIC_SELECT_PLACE = 'dynamic_select_place',
	PHONE = 'phone',
}

export type FieldTypeData = {
	slug: string;
	name: string;
	type: InputFieldType;
};

const generateFieldType = (slug: string, inputType: InputFieldType = InputFieldType.TEXT): FieldTypeData => {
	return {
		slug,
		name: i18n.t(slug),
		type: inputType,
	};
};

const optionalFields = {
	person: [
		generateFieldType('birthdate', InputFieldType.DATE),
		generateFieldType('gender', InputFieldType.SELECT),
		generateFieldType('birth_place', InputFieldType.DYNAMIC_SELECT_PLACE),
		generateFieldType('nationality', InputFieldType.DYNAMIC_SELECT_PLACE),
		generateFieldType('height', InputFieldType.TEXT),
		generateFieldType('weight', InputFieldType.TEXT),
		generateFieldType('website', InputFieldType.TEXT),
		generateFieldType('email', InputFieldType.TEXT),
	],
	organization: [
		generateFieldType('founding_date', InputFieldType.DATE),
		generateFieldType('founding_year', InputFieldType.TEXT),
		generateFieldType('website', InputFieldType.TEXT),
		generateFieldType('email', InputFieldType.TEXT),
	],
	place: [
		// geo fields are in diff component because their structure is nested obj
		generateFieldType('website', InputFieldType.TEXT),
		generateFieldType('telephone', InputFieldType.PHONE),
	],
};

export const getOptionalFieldsData = (entityType: string) => {
	if (entityType) {
		return optionalFields[entityType] || [];
	}

	return [];
};

export const notEditableAndDeletable = (entityType: string) => {
	return ['role', 'domain'].includes(entityType);
};

export const notDeletable = (entityType: string) => {
	return ['domain'].includes(entityType);
};

export enum CustomEntitiesTypes {
	ORGANIZATION = 'organization',
	PLACE = 'place',
	PERSON = 'person',
	ROLE = 'role',
	DOMAIN = 'domain',
}

export type DetailsErrorState = {
	domain: boolean;
	entity_type: boolean;
	slug: boolean;
};

export const fetchFirstWikiToOption = (entityId: string, entityType: string): Promise<Wiki | null> => {
	const projectStore = store.getState().project.currentProject;
	const headers = { Project: projectStore.domain };
	const contentLang = multiLingualService.checkIfProjectIsMultiLingual(projectStore.languages)
		? projectStore.languages.defaultLanguageCode.languageCode
		: projectStore.language;

	return HttpService.get(
		`/wiki-pages/search?query=*&entity_type=${entityType}&entity_ids=${entityId}${contentLang ? `&language=${contentLang}` : ''}`,
		null,
		headers,
	)
		.then((response) => response.data.data)
		.then((data) => data[0] || null)
		.catch(() => console.error('Error fetching wiki page'));
};

export const fetchPlaceToOptions = (searchedText: string) => {
	const contentMode = extractContentModeBasedOnUrl();
	const entityDomain = store.getState().customEntities[contentMode].contained_in_domain.slug;

	return CustomEntitiesHttpService.getDomainEntities(entityDomain, CustomEntitiesTypes.PLACE, searchedText)
		.then((response) => response.data.results)
		.then((data) => {
			return data.map((item: any) => {
				return { label: item.name, value: item.id, data: item };
			});
		});
};

export const getOptionalField = (optData: FieldTypeData, value: any, updateFunc: Function) => {
	const { name, slug, type } = optData;

	switch (type) {
		case InputFieldType.TEXT:
			return (
				<InputDesign
					invokeFunc={(text: string) => updateFunc(slug, text)}
					fieldId={slug}
					labelText={name}
					placeholderText={name}
					value={value}
				/>
			);
		case InputFieldType.SELECT:
			return (
				<SelectInputDesign
					invokeFunc={(option) => updateFunc(slug, option.value)}
					fieldId={slug}
					labelText={name}
					placeholderText={name}
					options={[
						{ name: i18next.t('male'), value: GenderEnum.MALE },
						{ name: i18next.t('female'), value: GenderEnum.FEMALE },
						{ name: i18next.t('other'), value: GenderEnum.OTHER },
					]}
					selectedValue={value}
				/>
			);
		case InputFieldType.DYNAMIC_SELECT_PLACE:
			return (
				<DynamicSelectInputDesign
					invokeFunc={(option) => updateFunc(slug, { id: option.value, name: option.label, slug: option.data.slug })}
					fetchResults={fetchPlaceToOptions}
					fieldId={slug}
					labelText={name}
					selectedValue={value ? { value: value.id, label: value.name, data: value } : undefined}
				/>
			);
		case InputFieldType.DATE:
			return (
				<SearchDateDesign
					invokeFunc={(selectedDate) => {
						const isValid = selectedDate && isDateObjValid(selectedDate);
						const formattedDate = isValid ? formatGeneralDateAsString(selectedDate) : null;
						formattedDate && updateFunc(slug, formattedDate);
					}}
					fieldId={slug}
					date={value}
					labelText={name}
					className={'custom-search-date-wrapper'}
				/>
			);
		case InputFieldType.PHONE:
			return (
				<PhoneInputDesign
					labelText={i18next.t('phone_placeholder')}
					placeholderText={i18next.t('phone_placeholder')}
					phoneData={value}
					invokeFunc={(phoneData: ITelephone) => updateFunc(slug, phoneData)}
					fieldId={slug}
				/>
			);
	}
};

interface OptionType {
	value: string;
	label: string;
}

export interface ICustomEntitiesEntityType {
	name: string;
	value: string;
	label?: string;
}

export const shouldDisableSlug = (customEntityType: string, isCreateMode: boolean) => {
	return isCreateMode ? false : customEntityType === CustomEntitiesTypes.DOMAIN || customEntityType === CustomEntitiesTypes.ROLE;
};

export const formatDataForPostRequest = (data: any) => {
	const formattedData = structuredClone(data);
	const fieldsWithContainedInDomain = ['contained_in_domain', 'contained_in_place', 'contained_in_organization'];
	const fieldsWithPlaces = ['birth_place', 'nationality'];
	const fieldsWithImages = [ImageTypes.DISPLAY_IMAGE, ImageTypes.ICON];

	fieldsWithContainedInDomain.forEach((fieldName) => {
		if (data[fieldName]) {
			formattedData[fieldName] = formattedData[fieldName].id;
		}
	});

	fieldsWithPlaces.forEach((fieldName) => {
		if (data[fieldName]) {
			formattedData[fieldName] = formattedData[fieldName].id;
		}
	});

	fieldsWithImages.forEach((fieldName) => {
		if (data[fieldName] && data[fieldName].url) {
			formattedData[fieldName] = {
				url: data[fieldName].url,
				original_filename: data[fieldName].original_filename || 'Placeholder name',
				timestamp: data[fieldName].timestamp || new Date().toISOString(),
			};
		}
	});

	if (data && data.social_media_links) {
		formattedData.social_media_links = data.social_media_links.filter((link: Record<string, string>) => link.value && link.value.length > 0);
	}

	if (data.roles && data.roles.length > 0) {
		// this will be valid only for entity type Person
		formattedData.roles = data.roles
			.filter(
				(role: IPersonRole) => (role.role && role.role.id) || (role.organization && role.organization.id) || (role.place && role.place.id),
			)
			.map((role: IPersonRole) => {
				return {
					role: role && role.role ? role.role.id : null,
					organization: role && role.organization ? role.organization.id : null,
					place: role && role.place ? role.place.id : null,
				};
			});
	}

	if (data && data.telephone && !data.telephone.country_iso_code) {
		// this will be valid only for entity type Place
		formattedData.telephone = null;
	}

	if (data && data.geo) {
		// this will be valid only for entity type Place
		if (!data.geo.latitude && !data.geo.longitude) {
			formattedData.geo = null;
		} else {
			formattedData.geo.latitude = data.geo.latitude ? data.geo.latitude : null;
			formattedData.geo.longitude = data.geo.longitude ? data.geo.longitude : null;
		}
	}

	Object.keys(formattedData).forEach((key) => {
		if (formattedData[key] === null || formattedData[key] === undefined || formattedData[key] === '') {
			delete formattedData[key];
		}
	});

	return formattedData;
};

export type UpdateCustomEntityReduxType = (dataForUpdate: Record<string, unknown>) => void;

export const generateRemoveModalContent = (translationFirstText: string): JSX.Element[] => {
	return [
		<span key={1}>{i18next.t(`${translationFirstText}`)}</span>,
		<br key={2} />,
		<br key={3} />,
		<span key={4}>{i18next.t('continue_confirmation')}</span>,
	];
};

export const customEntitiesAdvancedFiltersStyles = {
	control: (provided: StylesConfig, state: MultiValueGenericProps<OptionType>) => ({
		...provided,
		backgroundColor: '#FFFFFF',
		border: state.selectProps.menuIsOpen ? '1px solid #1058E1' : '1px solid #D9D9D9',
		borderRadius: '6px',
		marginBottom: '20px',
		width: '300px',
		justifyContent: 'space-between',
		boxShadow: state.selectProps.menuIsOpen ? '0 0 0 1px #1058E1' : 'none',
		'&:hover': {
			borderColor: '#1058E1',
		},
	}),
	indicatorSeparator: (provided: StylesConfig) => ({
		...provided,
		display: 'none',
		color: '#666666',
	}),
	dropdownIndicator: (provided: StylesConfig, state: MultiValueGenericProps<OptionType>) => ({
		...provided,
		color: state.selectProps.menuIsOpen ? '#1058E1' : '#666666',
		transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
		transition: 'transform 0.2s ease-out',
		'&:hover': {
			color: '#1058E1',
		},
	}),
	clearIndicator: (provided: StylesConfig, state: MultiValueGenericProps<OptionType>) => ({
		...provided,
		color: state.selectProps.menuIsOpen ? '#1058E1' : '#666666',
		'&:hover': {
			color: '#1058E1',
		},
	}),
	multiValue: (provided: StylesConfig) => ({
		...provided,
		borderRadius: '6px',
		padding: '2px 4px 2px 4px',
	}),
	multiValueLabel: (provided: StylesConfig) => ({
		...provided,
		fontFamily: 'Open Sans',
		fontWeight: '400',
		fontSize: '14px',
	}),
	multiValueRemove: (provided: StylesConfig) => ({
		...provided,
	}),
	singleValue: (provided: StylesConfig) => ({
		...provided,
		fontFamily: 'Open Sans',
		fontWeight: '400',
		fontSize: '14px',
	}),
	menuList: (provided: StylesConfig) => ({
		...provided,
		fontFamily: 'Open Sans',
		fontWeight: '400',
		fontSize: '14px',
	}),
	placeholder: (provided: StylesConfig) => ({
		...provided,
		color: 'grey',
		fontFamily: 'Open Sans',
		fontWeight: '400',
		fontSize: '14px',
	}),
};

export const generateDisclaimerHtmlElement = (disclaimerText: string, className: string) => {
	const disclaimerElement = { __html: i18next.t(`${disclaimerText}`) };
	return <span className={`${className}`} dangerouslySetInnerHTML={disclaimerElement} />;
};

export const generateNoOptionsField = (fieldId: string | undefined) => {
	switch (fieldId) {
		case i18next.t('contained_in_place'):
			return (
				<div className='disclaimer_message_container'>
					<div className='info-icon' />
					{generateDisclaimerHtmlElement('contained_in_place_disclaimer', 'domain_disclaimer_message')}
				</div>
			);
		case i18next.t('role'):
			return (
				<div className='disclaimer_message_container'>
					<div className='info-icon' />
					{generateDisclaimerHtmlElement('role_disclaimer', 'domain_disclaimer_message')}
				</div>
			);
		case i18next.t('role_organization'):
			return (
				<div className='disclaimer_message_container'>
					<div className='info-icon' />
					{generateDisclaimerHtmlElement('organization_disclaimer', 'domain_disclaimer_message')}
				</div>
			);
		case i18next.t('role_place'):
			return (
				<div className='disclaimer_message_container'>
					<div className='info-icon' />
					{generateDisclaimerHtmlElement('place_disclaimer', 'domain_disclaimer_message')}
				</div>
			);
		default:
			return i18next.t('no_options');
	}
};
