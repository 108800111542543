import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useReactQuery } from '../../../../../../services/react-query/react-query.helper';
import { extractRelatedPropertiesNameByUrl } from '../utils';
import { AppState } from '../../../../../../store/store';
import Related from '../../../../../../models/related/Related';
import { ICustomEntitiesReduxStore } from '../../../../../../store/reducers/content-sidebar-tags.reducer';
import SportsConnectionsHttpService from '../../../../Shared/advanced-content-filter/subcomponents/v2/services/sports-connections-http.service';
import { updateSuggestedEntities } from '../../../../../../store/action-creators/suggested-entities';
import { ISuggestedEntity, suggestedEntitiesAdditionalCacheQuery } from '../suggested-entities-v2.helper';
import { CustomEntitiesTypes } from '../../../../../Pages/CustomEntities/helpers/custom-entities.helper';
import { relatedConstants } from '../../../../../../constants/related.constants';
import { useMemo, useRef } from 'react';

export default function useFetchSuggestedEntities() {
	const dispatch = useDispatch();
	const previousDataRef = useRef<ISuggestedEntity[]>([]);

	const nestedReduxPropertyName = useMemo(() => extractRelatedPropertiesNameByUrl(), []);
	const sportsRelated: Related[] = useSelector((state: AppState) => {
		return nestedReduxPropertyName && state.tempSportsRelated[nestedReduxPropertyName]
			? state.tempSportsRelated[nestedReduxPropertyName]
			: [];
	});

	const customRelated = useSelector((state: AppState) => {
		const entities: ICustomEntitiesReduxStore = state.contentSidebar.tags.customEntities || {};
		return Object.values(entities)
			.flat()
			.map((entity) => entity.additional);
	}, shallowEqual);

	const sportRelatedIdsForRequest: string[] = sportsRelated
		.map((related) => related.data.uuid || related.data.id) // if it has uuid, it is a football entity
		.filter((relatedId) => relatedId.length > 5); // if its old entity will not have an uuid and we should not suggest for old football entities

	/// Get added entities ids to get full data
	const customRelatedIdsForRequest = customRelated.map((related) => related.id);
	const participantIds = [...sportRelatedIdsForRequest, ...customRelatedIdsForRequest];
	const participantsQueryData = useReactQuery(participantIds, () => SportsConnectionsHttpService.getLegacySportsData(participantIds), {
		...suggestedEntitiesAdditionalCacheQuery,
		enabled: participantIds.length > 0,
	});
	const formattedParticipantData: ISuggestedEntity[] =
		participantsQueryData.data && participantsQueryData.data.data ? participantsQueryData.data.data.results : [];

	/// Some entities have not expanded properties as competition_ids, team_ids etc. We need to get additionally to suggest them to the user
	const notExpandedPropsRequestIds = extractNotExpandedPropertiesForSuggesting(formattedParticipantData);
	const notExpandedPropsRequestIdsFiltered = notExpandedPropsRequestIds.filter((id) => !participantIds.includes(id));
	const notExpandedQueryData = useReactQuery(
		notExpandedPropsRequestIdsFiltered,
		() => SportsConnectionsHttpService.getLegacySportsData(notExpandedPropsRequestIdsFiltered),
		{
			...suggestedEntitiesAdditionalCacheQuery,
			enabled: notExpandedPropsRequestIdsFiltered.length > 0,
		},
	);
	const formattedNotExpandedData: ISuggestedEntity[] =
		notExpandedQueryData.data && notExpandedQueryData.data.data ? notExpandedQueryData.data.data.results : [];

	/// Extract expanded properties based on entity type
	const formattedExpandedData = formattedParticipantData.map((entity) => extractEntityPropBasedOnEntityType(entity)).flat();
	const formattedExpandedDataFiltered = formattedExpandedData.filter((expandedEntity) => {
		const isAlreadyExistAsSuggested = formattedNotExpandedData.findIndex((el) => el.id === expandedEntity.id) >= 0;
		return !isAlreadyExistAsSuggested;
	});

	/// Find suggested by paragraphs
	const alreadySuggestedEntities: ISuggestedEntity[] = useSelector((state: AppState) => {
		return state.suggestedEntities && Array.isArray(state.suggestedEntities.suggestedEntities)
			? state.suggestedEntities.suggestedEntities
			: [];
	});
	const suggestedFromParagraphs = alreadySuggestedEntities.filter((el) => Array.isArray(el.origin_ids) && el.origin_ids.length > 0);

	const dataForSuggesting = [...formattedNotExpandedData, ...formattedExpandedDataFiltered, ...suggestedFromParagraphs].filter(
		(el) => !(participantIds.includes(el.id) || participantIds.includes(el.uuid)),
	);

	const isDataChanged = JSON.stringify(previousDataRef.current) !== JSON.stringify(dataForSuggesting); // Check if data has actually changed
	isDataChanged && dispatch(updateSuggestedEntities(dataForSuggesting));
	previousDataRef.current = dataForSuggesting;
}

const extractNotExpandedPropertiesForSuggesting = (entities: ISuggestedEntity[]): string[] => {
	const result: string[] = [];
	entities.forEach((entity) => {
		if (Array.isArray(entity.competition_ids) && entity.competition_ids.length > 0) {
			entity.competition_ids.forEach((el: string) => result.push(el));
		}

		Array.isArray(entity.team_ids) && entity.team_ids.length > 0 && entity.team_ids.forEach((el: string) => result.push(el));
	});

	return result;
};

const extractEntityPropBasedOnEntityType = (entity: ISuggestedEntity): ISuggestedEntity[] => {
	const result: ISuggestedEntity[] = [];
	const nationality = (entity.nationality as ISuggestedEntity) || undefined;
	const parent_place = (entity.contained_in_place as ISuggestedEntity) || undefined;

	const coach = (entity.coach as ISuggestedEntity) || undefined;
	const team = (entity.team as ISuggestedEntity) || undefined;

	switch (entity.entity_type) {
		case CustomEntitiesTypes.PERSON:
			nationality && result.push(nationality);
			break;
		case CustomEntitiesTypes.PLACE:
			parent_place && result.push(parent_place);
			break;
		case relatedConstants.types.team:
			coach && result.push(coach);
			break;
		case relatedConstants.types.player:
			team && result.push(team);
			break;
	}

	return result;
};
