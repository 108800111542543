import React from 'react';
import { Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import BlockModel from '../../../../models/block.model';
import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import TournamentSelectComponent from '../../../partials/tournament-select/tournament-select.component';
import SeasonSelectComponent from '../../../partials/season-select/season-select.component';
import ErrorHandler from '../../../partials/error-handler/error-handler';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import FootballTopScorersModel from '../models/football-top-scorers.model';
import FootballTopScorersTeamSelect from '../../../partials/top-scorers-selects/football-top-scorers-team-select';
import FootballTopScorersSelect from '../../../partials/top-scorers-selects/football-top-scorers-select';
import { createBlock } from '../../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import PlayerModel from '../../../../../../../models/v2/player/entity/player.model';
import { ElementsSelect } from '../../../partials/elements-select/widget-elements-select';
import { playerH2HStatsOptions, topScorersStatsOptions } from '../../football-team/constants/football-team.constants';
import { onRefreshTimeChangeFromMainComponent } from '../../../partials/refresh-time-input/helper';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import RefreshTimeWrapper from '../../../partials/refresh-time-input/refresh-time-wrapper';
type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: FootballTopScorersModel;
	isValid: boolean;
};

export default class FootballTopScorersEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetFootballTopScorers,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { widgetModel: this.state.widgetModel };
		const isValid = validation.validate(this.props.block, data) === true;

		this.setState({ ...this.state, isValid });

		const topScorersModel = FootballTopScorersModel.builder(this.state.widgetModel).build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: topScorersModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateWidgetModelState = (model: FootballTopScorersModel) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onTournamentSelect = (tournament: TournamentModel) => {
		this.updateWidgetModelState(
			FootballTopScorersModel.builder(this.state.widgetModel)
				.withTournament(tournament)
				.withSeason({} as SeasonModel)
				.withTopScorers([])
				.withTeams([] as TeamModel[])
				.build(),
		);
	};

	onSeasonSelect = (season: SeasonModel) => {
		this.updateWidgetModelState(
			FootballTopScorersModel.builder(this.state.widgetModel)
				.withSeason(season)
				.withTopScorers([])
				.withTeams([] as TeamModel[])
				.build(),
		);
	};

	onTopScorersChange = (topScorers: any[]) => {
		const { widgetModel } = this.state;
		topScorers = topScorers ? topScorers.map((topScorer: any) => topScorer.data) : [];
		this.updateWidgetModelState(FootballTopScorersModel.builder(widgetModel).withTopScorers(topScorers).build());
	};

	onTeamsChange = (teams: TeamModel[]) => {
		teams = teams ? teams.map((team: any) => team.data) : [];
		this.updateWidgetModelState(FootballTopScorersModel.builder(this.state.widgetModel).withTeams(teams).build());
	};

	onFilterStatisticsChange = (e: any) => {
		this.updateWidgetModelState(
			FootballTopScorersModel.builder(this.state.widgetModel)
				.withFilter(e.target.checked)
				.withTeams([] as TeamModel[])
				.withTopScorers([])
				.withPlayers([])
				.build(),
		);
	};

	onLimitChange = (limit: string) => {
		this.updateWidgetModelState(FootballTopScorersModel.builder(this.state.widgetModel).withLimit(limit).build());
	};

	onStartPositionChange = (startFrom: string) => {
		this.updateWidgetModelState(FootballTopScorersModel.builder(this.state.widgetModel).withStartFrom(startFrom).build());
	};

	onPlayersChange = (players: PlayerModel[]) => {
		this.updateWidgetModelState(FootballTopScorersModel.builder(this.state.widgetModel).withPlayers(players).build());
	};

	onStatsParametersChange = (statsParameters: string[]) => {
		this.updateWidgetModelState(FootballTopScorersModel.builder(this.state.widgetModel).withStats(statsParameters).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, FootballTopScorersModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	render() {
		const { widgetModel, isValid } = this.state;
		const placeholder =
			widgetModel.players && widgetModel.players.length
				? `${this.props.t('from')} 1 ${this.props.t('to')} ${widgetModel.players.length}`
				: `1, 4, 7, ${this.props.t('etc')}`;
		return (
			<>
				<div data-qa='football-top-scorers-edit-component'>
					<Row>
						<Col>
							<FormGroup>
								<TournamentSelectComponent
									isMulti={false}
									isBlocky
									isRequired
									isValid={isValid}
									selectedTournament={widgetModel.tournament}
									onTournamentSelect={this.onTournamentSelect}
									t={this.props.t}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TOURNAMENT_SELECT}`}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<SeasonSelectComponent
									withLabel
									isValid={isValid}
									isBlocky
									selectedSeason={widgetModel.season}
									isClearable={widgetModel.season && !widgetModel.season.id}
									tournamentId={widgetModel.tournament && widgetModel.tournament.id ? widgetModel.tournament.id : ''}
									onSeasonSelect={this.onSeasonSelect}
									t={this.props.t}
									preselectSeason
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.SEASON_SELECT}`}
								/>
							</FormGroup>
						</Col>
					</Row>
					{widgetModel.season && widgetModel.season.id && (
						<Row className={'mb-2'}>
							<Col>
								<hr />
								<FormGroup key={`filter-statistics-team`} check inline>
									<Label>
										<Input
											key={`display-filter-by-team-input`}
											className='form-check-input'
											type='checkbox'
											id={`filter-by-team-${this.props.block.id}`}
											name='filter-statistics-label-team'
											value='team'
											checked={widgetModel.filter}
											onChange={this.onFilterStatisticsChange}
										/>
										{this.props.t('filter_statistics_by_team')}
									</Label>
								</FormGroup>
							</Col>
						</Row>
					)}
					{widgetModel.filter && widgetModel.season && widgetModel.season.id && (
						<Row>
							<Col>
								<FormGroup>
									<FootballTopScorersTeamSelect
										withLabel
										isBlocky
										isRequired={false}
										isValid={isValid}
										selectedSeason={widgetModel.season}
										selectedTeams={widgetModel.teams}
										onTeamSelect={this.onTeamsChange}
										t={this.props.t}
										id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TOP_SCORERS_TEAM_SELECT}`}
									/>
								</FormGroup>
							</Col>
						</Row>
					)}
					{widgetModel.tournament && widgetModel.tournament.id && widgetModel.season && widgetModel.season.id && (
						<Row className='mb-2'>
							<Col>
								<FootballTopScorersSelect
									selectedTeams={widgetModel.teams}
									seasonId={widgetModel.season && widgetModel.season.id && widgetModel.season.id}
									isValid={isValid}
									isRequired={false}
									selectedTopScorers={widgetModel.topScorers}
									onTopScorersSelect={this.onTopScorersChange}
									t={this.props.t}
									isFilterApplied={widgetModel.filter}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TOP_SCORERS_SELECT}`}
									setPlayers={this.onPlayersChange}
									players={widgetModel.players}
								/>
							</Col>
						</Row>
					)}
					{}
					<Row className='mt-2' data-qa='football-top-scorers-elements-select'>
						<Col>
							<FormGroup>
								<Label htmlFor={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.ELEMENTS}`}>{this.props.t('select_stats')}</Label>
								<ElementsSelect
									value={widgetModel.stats}
									onSelect={this.onStatsParametersChange}
									t={this.props.t}
									isMulti
									elements={topScorersStatsOptions}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col className='mt-2'>
							<FormGroup>
								<Label>{this.props.t('start_from_position')}:</Label>
								<Input
									type='number'
									className='form-control'
									value={widgetModel.startFrom ? widgetModel.startFrom : ''}
									min={1}
									placeholder={placeholder}
									max={widgetModel.players && widgetModel.players.length}
									onChange={(event: any) => this.onStartPositionChange(event.target.value)}
									invalid={widgetModel.players && widgetModel.players.length < +widgetModel.startFrom}
								/>
								<FormFeedback>
									{this.props.t('must_be_less_than')} {widgetModel && widgetModel.players && widgetModel.players.length + 1}
								</FormFeedback>
							</FormGroup>
						</Col>
						<Col className='mt-2'>
							<FormGroup>
								<Label>{this.props.t('show')}:</Label>
								<Input
									type='number'
									className='form-control'
									value={widgetModel.limit ? widgetModel.limit : ''}
									onChange={(event: any) => this.onLimitChange(event.target.value)}
									placeholder={this.props.t('players_in_most_decorated_players')}
								/>
							</FormGroup>
						</Col>
					</Row>
					<div className='mb-3'>
						<ErrorHandler
							arePropsValid={isValid}
							isValueSelected={widgetModel.tournament && !widgetModel.tournament.id}
							t={this.props.t}
							errorMessage='no_tournament_selected'
						/>
					</div>
					<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
				</div>
			</>
		);
	}
}
