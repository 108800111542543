import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import FootballTeamSquadWidgetModel from './football-team-squad.model';
import { FootballTeamSquadWidgetJson } from './football-team-squad.json';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class FootballTeamSquadWidgetBuilder {
	readonly json: FootballTeamSquadWidgetJson;

	constructor(team?: FootballTeamSquadWidgetModel | FootballTeamSquadWidgetJson) {
		if (team && team instanceof FootballTeamSquadWidgetModel) {
			this.json = team.toJson();
		} else if (team) {
			this.json = team;
		} else {
			this.json = {} as FootballTeamSquadWidgetJson;
		}
	}

	withTournamentSeason = (tournamentSeason: EntitySeasonsSelectModel): FootballTeamSquadWidgetBuilder => {
		this.json.tournamentSeason = tournamentSeason;

		return this;
	};

	withTeam(team: TeamModel): FootballTeamSquadWidgetBuilder {
		this.json.team = team;

		return this;
	}

	withHeaderDefaultOption(headerDefaultOption: any): FootballTeamSquadWidgetBuilder {
		this.json.headerDefaultOption = headerDefaultOption;

		return this;
	}

	withElements(elements: string[]): FootballTeamSquadWidgetBuilder {
		this.json.elements = elements;

		return this;
	}

	withDisplayHeader(displayHeader: boolean): FootballTeamSquadWidgetBuilder {
		this.json.displayHeader = displayHeader;

		return this;
	}

	build(): FootballTeamSquadWidgetModel {
		return FootballTeamSquadWidgetModel.fromJson(this.json);
	}
}
