import { ContentTypes, DynamicContentProperty } from '../constants/content-types';
import { getModelByContentTypeGlobal } from '../services/content-models-service/ContentModelService';

export const getDynamicProperties = (
	response: any,
	contentType:
		| ContentTypes.ARTICLE
		| ContentTypes.VIDEO
		| ContentTypes.GALLERY
		| ContentTypes.WIKI_PAGE
		| ContentTypes.BANNER
		| ContentTypes.CATEGORY,
): DynamicContentProperty[] => {
	const contentModel = getModelByContentTypeGlobal(contentType);

	if (contentModel) {
		return contentModel.extractDynamicPropertiesKeys().map((propertyKey: string) => {
			return { name: propertyKey, value: response[propertyKey] || false, slug: propertyKey };
		});
	}

	return [];
};
