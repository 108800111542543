import { extractTournaments } from '../../../../partials/player-seasons/helpers/players-seasons-select.helper';
import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import { Season, Seasons } from '../../../types/seasons';

export const mapResponseToModel = (data: Season) => {
	const model = new EntitySeasonsSelectModel();

	model.seasonId = data.id;
	model.seasonName = data.name;
	model.tournamentId = data.tournament.id;
	model.tournamentName = data.tournament.name;

	return model;
};

export const mapResponseListToTeamSeasonsModel = (response: Season[]): EntitySeasonsSelectModel[] => {
	if (response && response.length > 0) {
		return response.map((item: Season) => mapResponseToModel(item));
	} else return [];
};

export const createTournamentGroupOptions = (modelList: EntitySeasonsSelectModel[]) => {
	let options: Seasons[] = [];

	if (modelList && modelList.length > 0) {
		const tournaments = extractTournaments(modelList);

		tournaments.forEach((tournament: string) => {
			const tournamentGroups: { label: string; value: string; data: EntitySeasonsSelectModel }[] = [];
			modelList.forEach((model: EntitySeasonsSelectModel) => {
				if (model.tournamentName === tournament) {
					tournamentGroups.push({
						label: model.tournamentName + '-' + model.seasonName,
						value: model.seasonId,
						data: model,
					});
				}
			});

			const option = { label: tournament, options: [...tournamentGroups] };
			options.push(option);
		});
	}

	return options;
};

export const teamSeasonToOption = (season: EntitySeasonsSelectModel) => {
	if (season) {
		return {
			value: season.seasonId,
			label: season.tournamentName + '-' + season.seasonName,
			data: season,
			type: 'team-season',
		};
	} else {
		return {};
	}
};
