import { call, put, takeEvery } from 'redux-saga/effects';
import HttpService from '../../services/rest/HttpService';
import {
	returnObjectForProjectAuthorListingReceived,
	returnObjectForProjectDefaultAuthorReceived,
	setProjectAuthorSocials,
} from '../action-creators/AuthorActionCreators';
import {
	distributionChannelsReceived,
	distributionRegionsReceived,
	PROJECT_RESOURCES_RECEIVED,
	projectFeaturesReceived,
	projectResourcesReceived,
	SPORTS_RECEIVED,
	sportsReceived,
} from '../action-creators/ProjectResourcesActionCreators';
import {
	returnObjectForAdminCategoryListingReceived,
	returnObjectForAllCategoryListingReceived,
} from '../action-creators/CategoriesActionCreators';
import { PROJECT_RESOURCES_REQUEST } from '../../constants/constants';
import { onError, setLoadingState } from '../action-creators/GeneralActions';
import {
	requestArticleTypesReceived,
	requestGalleryTypesReceived,
	requestImageTypesReceived,
	requestListTypesReceived,
	requestTagTypesReceived,
	requestVideoTypesReceived,
	requestWikiPagesTypesReceived,
} from '../action-creators/types-action.creator';
import { imageOwnersReceived } from '../action-creators/ImageOwnersActionCreator';
import { actionService, sportsConnectionsService } from '../../App';
import { logout } from '../action-creators/ProfileActionCreators';
import {
	requestArticleStatusesReceived,
	requestBannerStatusesReceived,
	requestGalleryStatusesReceived,
	requestListStatusesReceived,
	requestPollsStatusesReceived,
	requestVideoStatusesReceived,
	requestWikiStatusesReceived,
} from '../action-creators/status-action-creator';
import {
	requestArticleOriginsReceived,
	requestGalleryOriginsReceived,
	requestImageOriginsReceived,
	requestVideoOriginsReceived,
} from '../action-creators/origins-action.creator';
import {
	requestArticleCommentsPoliciesReceived,
	requestGalleryCommentsPoliciesReceived,
	requestPollsCommentsPoliciesReceived,
	requestVideoCommentsPoliciesReceived,
} from '../action-creators/comment-policy-action.creator';
import {
	requestArticlePropertiesReceived,
	requestBannerPropertiesReceived,
	requestCategoryPropertiesReceived,
	requestGalleryPropertiesReceived,
	requestVideoPropertiesReceived,
	requestWikiPropertiesReceived,
} from '../action-creators/properties-action-creator';
import AuthorSocialModel from '../../models/v2/author/social.model';

function* fetchProjectResources(action: any) {
	yield put(setLoadingState(true));
	try {
		let headers = { Project: action.payload.project.domain };
		let allAuthors = yield call(HttpService.getAllPages, `/authors`, null, headers, 200);
		let tempAuthors: any[] = [];
		allAuthors = allAuthors.map((item: any) => item.data.data);
		allAuthors.forEach((item: any[]) => tempAuthors.push(...item));

		let defaultAuthor = tempAuthors.filter((author) => author.default);
		yield put(returnObjectForProjectAuthorListingReceived(tempAuthors));
		yield put(returnObjectForProjectDefaultAuthorReceived(defaultAuthor[0]));
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		onError(error);
	}

	try {
		let formattedSocials: AuthorSocialModel[] = [];
		const headers = { Project: action.payload.project.domain };
		const socialsResponse = yield call(HttpService.get, `/links/social_media`, null, headers);

		if (socialsResponse && socialsResponse.data && socialsResponse.data.data && socialsResponse.data.data.length > 0) {
			const socials = socialsResponse.data.data;
			formattedSocials = socials.map((social: Record<string, unknown>) => {
				return { slug: social.slug, name: social.name, value: '' } as AuthorSocialModel;
			});
		}

		yield put(setProjectAuthorSocials(formattedSocials));
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		onError(error);
	}

	try {
		let headers = { Project: action.payload.project.domain };
		let allCategories = yield call(HttpService.get, `/categories?page=1&limit=200`, null, headers);

		yield put(returnObjectForAllCategoryListingReceived(allCategories.data.data));
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		const projectId = action.payload.project.id;
		let project = yield call(HttpService.getProjectClientAPI, projectId);
		yield put(projectFeaturesReceived(project.data.features));
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		const sports = yield call(sportsConnectionsService.getSports);
		yield put(sportsReceived(sports));
		actionService.emitProjectResourcesAction({ type: SPORTS_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		let adminCategories = yield call(HttpService.get, `/admins/${action.payload.adminId}/categories`, null, headers);

		yield put(returnObjectForAdminCategoryListingReceived(adminCategories.data.data));
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const origins = yield call(HttpService.get, `images/origins?page=1&limit=200`, null, headers);
		yield put(imageOwnersReceived(origins));
		yield put(requestImageOriginsReceived(origins));
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const origins = yield call(HttpService.get, `articles/origins?page=1&limit=200`, null, headers);
		yield put(requestArticleOriginsReceived(origins));
	} catch (error) {
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const origins = yield call(HttpService.get, `videos/origins?page=1&limit=200`, null, headers);
		yield put(requestVideoOriginsReceived(origins));
	} catch (error) {
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const origins = yield call(HttpService.get, `galleries/origins?page=1&limit=200`, null, headers);
		yield put(requestGalleryOriginsReceived(origins));
	} catch (error) {
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const regions = yield call(HttpService.get, `distribution/regions`, action.payload.project.id, headers);
		yield put(distributionRegionsReceived(regions.data.data));
	} catch (error) {
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const channels = yield call(HttpService.get, `distribution/channels`, action.payload.project.id, headers);
		yield put(distributionChannelsReceived(channels.data.data));
	} catch (error) {
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const types = yield call(HttpService.get, `/articles/types`, null, headers);
		yield put(requestArticleTypesReceived(types));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const types = yield call(HttpService.get, `/videos/types`, null, headers);
		yield put(requestVideoTypesReceived(types));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const types = yield call(HttpService.get, `/images/types`, null, headers);
		yield put(requestImageTypesReceived(types));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const types = yield call(HttpService.get, `/galleries/types`, null, headers);
		yield put(requestGalleryTypesReceived(types));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const types = yield call(HttpService.get, `/lists/types`, null, headers);
		yield put(requestListTypesReceived(types));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const types = yield call(HttpService.get, `/wiki-pages/types`, null, headers);
		yield put(requestWikiPagesTypesReceived(types));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const types = yield call(HttpService.get, `/tags/types`, null, headers);
		yield put(requestTagTypesReceived(types));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const statuses = yield call(HttpService.get, `/articles/statuses`, null, headers);
		yield put(requestArticleStatusesReceived(statuses));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const statuses = yield call(HttpService.get, `/videos/statuses`, null, headers);
		yield put(requestVideoStatusesReceived(statuses));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const statuses = yield call(HttpService.get, `/polls/statuses`, null, headers);
		yield put(requestPollsStatusesReceived(statuses));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const statuses = yield call(HttpService.get, `/galleries/statuses`, null, headers);
		yield put(requestGalleryStatusesReceived(statuses));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const statuses = yield call(HttpService.get, `/wiki-pages/statuses`, null, headers);
		yield put(requestWikiStatusesReceived(statuses));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const statuses = yield call(HttpService.get, `/lists/statuses`, null, headers);
		yield put(requestListStatusesReceived(statuses));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const statuses = yield call(HttpService.get, `/banners/statuses`, null, headers);
		yield put(requestBannerStatusesReceived(statuses));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	// Properties
	try {
		let headers = { Project: action.payload.project.domain };
		const properties = yield call(HttpService.get, `/articles/properties`, null, headers);
		yield put(requestArticlePropertiesReceived(properties));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const properties = yield call(HttpService.get, `/videos/properties`, null, headers);
		yield put(requestVideoPropertiesReceived(properties));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const properties = yield call(HttpService.get, `/galleries/properties`, null, headers);
		yield put(requestGalleryPropertiesReceived(properties));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const properties = yield call(HttpService.get, `/wiki-pages/properties`, null, headers);
		yield put(requestWikiPropertiesReceived(properties));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const properties = yield call(HttpService.get, `/banners/properties`, null, headers);
		yield put(requestBannerPropertiesReceived(properties));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const properties = yield call(HttpService.get, `/categories/properties`, null, headers);
		yield put(requestCategoryPropertiesReceived(properties));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const commentsPolicies = yield call(HttpService.get, `/articles/comments/policies`, null, headers);
		yield put(requestArticleCommentsPoliciesReceived(commentsPolicies));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const commentsPolicies = yield call(HttpService.get, `/videos/comments/policies`, null, headers);
		yield put(requestVideoCommentsPoliciesReceived(commentsPolicies));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const commentsPolicies = yield call(HttpService.get, `/polls/comments/policies`, null, headers);
		yield put(requestPollsCommentsPoliciesReceived(commentsPolicies));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}

	try {
		let headers = { Project: action.payload.project.domain };
		const commentsPolicies = yield call(HttpService.get, `/galleries/comments/policies`, null, headers);
		yield put(requestGalleryCommentsPoliciesReceived(commentsPolicies));
		yield put(projectResourcesReceived());
		actionService.emitProjectResourcesAction({ type: PROJECT_RESOURCES_RECEIVED });
	} catch (error) {
		const { history } = action.payload;
		history.push('/login');
		logout();
		yield put(onError(error));
	}
	yield put(setLoadingState(false));
}

function* projectSelectSaga() {
	yield takeEvery(PROJECT_RESOURCES_REQUEST, fetchProjectResources);
}

export default projectSelectSaga;
