import React from 'react';
import { Row, Col, Label, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Properties, State } from './Properties/CategoryEditProperties';
import { Title } from '../../Partials/Fields/title/TitleComponent';
import Category from '../../../models/category/Category';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Project from '../../../models/project/Project';
import {
	CATEGORY_ENTITY_RECEIVED,
	CATEGORY_ENTITY_REQUEST,
	CATEGORY_ENTITY_UPDATE,
	CATEGORY_ENTITY_UPDATE_FAILED,
	CATEGORY_ENTITY_UPDATE_SUCCESS,
	returnObjectForCategoryEntityRequest,
	returnObjectForCategoryEntityUpdate,
	returnObjectForCategoryListingRequest,
} from '../../../store/action-creators/CategoriesActionCreators';
import { Description } from '../../Partials/Fields/description/DescriptionComponent';
import Seo from '../../Partials/Sidebar/Seo/SeoComponent';
import {
	seoComponentModelToCategory,
	categoryToSeoComponentModel,
	urlsComponentModelToCategory,
	categoryToUrlsComponentModelForEdit,
} from './Helpers/CategoryHelper';
import { SeoComponentModel } from '../../Partials/Sidebar/Seo/models/SeoComponentModel';
import MainMedia from '../../../models/mainmedia/MainMedia';
import MainMediaContainer from '../../Partials/Sidebar/Media/MainMediaListContainer';
import { Active } from '../../Partials/Fields/active/ActiveComponent';
import Urls from '../../Partials/Sidebar/Urls/urls.component';
import { UrlsComponentModel } from '../../Partials/Sidebar/Urls/models/UrlsComponentModel';
import BlockableButtonWrapper from '../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';
import { ContentTypes } from '../../../constants/content-types';
import { actionService } from '../../../App';
import SeoModel from '../../../models/seo/Seo';
import SidebarCollapsibleElements from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-collapsible-elements';
import SidebarElementsToggle from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-elements-toggle/sidebar-elements-toggle.component';
import { Subscription } from 'rxjs';
import {
	ContentSidebarChangeCollapseStatus,
	onChangeCollapseStatus,
	onSidebarElemSort,
	toggleSidebarEditMode,
} from '../../../services/content-sidebar/content-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';
import GlobalModal from '../../Partials/Modals/Global/global-modal';
import {
	appendBeforeUnloadResetTempSidebar,
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	saveSidebarSettingsByContentType,
} from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';
import ContentProperties from '../../Partials/Sidebar/general-content/components/ContentProperties';

class CategoryEdit extends React.Component<Properties, State> {
	actionServiceSubscription: Subscription = {} as Subscription;

	constructor(props: Properties) {
		super(props);
		this.state = {
			category: props.category,
			isContentLoading: false,
			isSidebarInEdit: false,
			openSidebarSettingsModalFlag: false,
		};
		this.registerActionEventListener();
	}

	registerActionEventListener() {
		this.actionServiceSubscription = actionService.onActionReceived().subscribe((action: string) => {
			if (action === CATEGORY_ENTITY_REQUEST) {
				this.toggleContentLoadingState(true);
			}

			if (action === CATEGORY_ENTITY_RECEIVED) {
				this.toggleContentLoadingState(false);
			}
		});
	}

	toggleContentLoadingState(isLoading: boolean) {
		this.setState({
			...this.state,
			isContentLoading: isLoading,
		});
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.props.getCategory(this.props.match.params.id, this.props.project);
		this.props.getCategories('1', this.props.project, this.props.adminId);
		appendBeforeUnloadResetTempSidebar(ContentTypes.CATEGORY);
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any): void {
		if (prevProps.category !== this.props.category) {
			this.setState({ category: this.props.category });
		}
	}

	componentWillUnmount() {
		this.actionServiceSubscription.unsubscribe();
	}

	initPageTitle() {
		document.title = this.props.t('category_edit');
	}

	onTitleChange(title: string) {
		const seo = SeoModel.builder(this.state.category.seo).withAutoUpdateSeo(title).build();
		const category = Category.builder(this.state.category).withTitle(title).withSeo(seo).build();
		this.setState({ category });
	}

	onSeoChange(seoModel: SeoComponentModel) {
		const category = seoComponentModelToCategory(seoModel, this.state.category);
		this.setState({ category });
	}

	onMainMediaChange(mainMedia: MainMedia[]) {
		let category = Category.builder(this.state.category).withMainMedia(mainMedia).build();
		this.setState({ category });
	}

	onDescriptionChange(description: string) {
		const category = Category.builder(this.state.category).withDescription(description).build();
		this.setState({ category });
	}

	onActiveChange(active: boolean) {
		this.setState({ category: Category.builder(this.state.category).withActive(active).build() });
	}

	onUrlsChange(urlsModel: UrlsComponentModel) {
		const category = urlsComponentModelToCategory(urlsModel, this.state.category);
		this.setState({ category });
	}

	onSubmit() {
		this.props.updateCategory(this.state.category, this.props.project);
	}

	toggleSidebarEditMode = () => {
		const newState = toggleSidebarEditMode({ ...this.state }, ContentTypes.CATEGORY);
		this.setState(newState);
	};

	render() {
		const { t, project } = this.props;
		const seoModel = categoryToSeoComponentModel(this.state.category);

		const urlsModel = categoryToUrlsComponentModelForEdit(this.state.category);
		const sidebarComponentsMap = {
			general: <ContentProperties />,
			media: (
				<MainMediaContainer
					onChange={this.onMainMediaChange.bind(this)}
					value={this.state.category ? this.state.category.mainMedia : ({} as MainMedia[])}
					currentProject={project}
					contentType={ContentTypes.CATEGORY}
					applyWatermark={false}
				/>
			),
			urls: <Urls contentType={ContentTypes.CATEGORY} onChange={this.onUrlsChange.bind(this)} value={urlsModel} />,
			seo: <Seo onChange={this.onSeoChange.bind(this)} value={seoModel} />,
		};
		const { isSidebarInEdit, openSidebarSettingsModalFlag } = this.state;

		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col='8' lg='8' md='12' sm='12' xs='12'>
						<div className='card'>
							<div className='card-header'>
								<div className={'d-flex align-items-center'}>
									<span className={'mr-auto mb-0 text-truncate'}>
										{t('category_edit')}: {this.props.category ? this.props.category.title : ''}
									</span>
									<BlockableButtonWrapper
										blockOnActions={[CATEGORY_ENTITY_UPDATE]}
										releaseOnActions={[CATEGORY_ENTITY_UPDATE_SUCCESS, CATEGORY_ENTITY_UPDATE_FAILED]}
									>
										<Button
											size='sm'
											color='primary'
											id='category-edit-save-top'
											className={'ml-auto text-nowrap'}
											onClick={this.onSubmit.bind(this)}
										>
											<i className='fa fa-floppy-o' /> {t('save_category')}
										</Button>
									</BlockableButtonWrapper>
								</div>
							</div>
							<div className='card-body'>
								<div className={`${this.state.isContentLoading ? 'loading-overlay' : ''}`}>
									<Row>
										<Col xs='12'>
											<Label htmlFor='title'>{t('title')}</Label>
											<Title
												t={t}
												onChange={this.onTitleChange.bind(this)}
												value={this.state.category ? this.state.category.title : ''}
												dataQaProperty={'default-title'}
											/>
										</Col>
									</Row>

									<Row>
										<Col xs='12'>
											<Description
												t={t}
												onChange={this.onDescriptionChange.bind(this)}
												showValidation={true}
												value={this.state.category ? this.state.category.description : ''}
											/>
										</Col>
									</Row>

									<Row>
										<Col xs='12'>
											<Active
												t={t}
												onChange={this.onActiveChange.bind(this)}
												value={this.state.category.active}
												isChecked={this.state.category.active}
											/>
										</Col>
									</Row>

									<Row className={'mt-4'}>
										<Col xs='12'>
											<BlockableButtonWrapper
												blockOnActions={[CATEGORY_ENTITY_UPDATE]}
												releaseOnActions={[CATEGORY_ENTITY_UPDATE_SUCCESS, CATEGORY_ENTITY_UPDATE_FAILED]}
											>
												<Button
													color='primary'
													id='category-edit-save-bottom'
													className={'text-uppercase font-weight-bold'}
													onClick={this.onSubmit.bind(this)}
												>
													<i className='fa fa-floppy-o' /> {t('save_category')}
												</Button>
											</BlockableButtonWrapper>
										</Col>
									</Row>
								</div>
							</div>
						</div>
					</Col>
					<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
						<SidebarElementsToggle t={t} toggleSidebarEditMode={this.toggleSidebarEditMode} isSidebarInEdit={isSidebarInEdit} />
						<SidebarCollapsibleElements
							isSidebarInEdit={isSidebarInEdit}
							onElemSort={(settingsValue: FormattedSidebarSectionValue[]) => onSidebarElemSort(settingsValue, ContentTypes.CATEGORY)}
							onChangeCollapseStatus={(data: ContentSidebarChangeCollapseStatus) => onChangeCollapseStatus(data)}
							contentType={ContentTypes.CATEGORY}
							sidebarComponentsMap={sidebarComponentsMap}
							t={t}
						/>
					</Col>
				</Row>
				<GlobalModal
					isOpen={openSidebarSettingsModalFlag}
					t={this.props.t}
					headerContent='save_sidebar_settings'
					bodyContent='unsaved_changes'
					submitFunction={() => saveSidebarSettingsByContentType(ContentTypes.CATEGORY, t)}
					beforeCloseFunction={() =>
						overwriteTempSidebarByContentType(ContentTypes.CATEGORY, defaultTempSidebarFromApiResponse[ContentTypes.CATEGORY].value)
					}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		category: state.category.category,
		allCategories: state.category.allCategories,
		adminCategories: state.category.adminCategories,
		project: state.project.currentProject,
		profile: state.profile.profile,
		adminId: state.profile.profile.id,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateCategory: (category: Category, project: Project) => dispatch(returnObjectForCategoryEntityUpdate(category, project)),
		getCategory: (id: string, project: Project) => dispatch(returnObjectForCategoryEntityRequest(id, project)),
		getCategories: (page: string, project: Project, adminId: string) => {
			dispatch(returnObjectForCategoryListingRequest(page, project, adminId));
		},
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps), withRouter)(CategoryEdit) as React.ComponentType;
