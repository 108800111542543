import moment from 'moment';

export const updateDateTimeReduxState = (dates: Date[] | null, updateTempProperty: Function, reduxPropertyKey: string) => {
	try {
		const selectedDate = dates && dates.length >= 0 ? dates[0] : '';
		updateTempProperty({ [reduxPropertyKey]: selectedDate });
	} catch (error) {
		console.log(error);
	}
};

export const updateContentUpdatedDateTimeReduxState = (dates: Date[] | null, updateTempProperty: Function, reduxPropertyKey: string) => {
	try {
		const selectedDate = dates && dates.length >= 0 ? dates[0] : '';
		updateTempProperty({ [reduxPropertyKey]: selectedDate ? moment(selectedDate).format('YYYY-MM-DDTHH:mm:ssZ') : '' });
	} catch (error) {
		console.log(error);
	}
};

export const updateIsContentUpdatedDateTimeAutomaticallySetReduxState = (
	isSet: boolean,
	updateTempProperty: Function,
	reduxPropertyKey: string,
) => {
	try {
		updateTempProperty({ [reduxPropertyKey]: isSet });
	} catch (error) {
		console.log(error);
	}
};
