import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { DATA_QA_ATTRIBUTES } from '../constants/data-qa.attributes';
import { wrapComponents } from '../helpers/general-content-attributes.helper';
import { useTranslation } from 'react-i18next';
import {
	extractContentModeBasedOnUrl,
	extractMainContentTypeBasedOnUrl,
	isCreateScreen,
} from '../../../../../global-helpers/global.helpers';
import {
	generatePropsNamingByContentType,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../../services/content-models-service/ContentModelService';
import { connect } from 'react-redux';
import ContentAttribute from '../../../../../models/v2/content-attributes/content-attributes.model';
import { ReduxGeneralContentProps } from '../constants/redux-general-content.attributes';
import { FormGroup, Label } from 'reactstrap';
import ContentProperty from '../subcomponents/properties/property';
import { extractPropertyNameFromRedux } from '../helpers/properties.helper';
import { DynamicObjectWithKeys } from '../../../../../constants/content-types';

export type DynamicPropertiesChange = { key: string; checked: boolean };

type Properties = {
	contentModelProperties: Record<string, boolean>;
	contentProperties: ContentAttribute[];
	updateContentProperty: (propertyObject: Record<string, any>) => void;
	contentInitialProperties?: Record<string, boolean>;
	onDynamicPropertiesChange?: (dynamicProperties: DynamicPropertiesChange) => void;
};

const ContentProperties: FunctionComponent<Properties> = ({
	contentModelProperties,
	contentProperties,
	updateContentProperty,
	contentInitialProperties,
	onDynamicPropertiesChange,
}) => {
	const [t] = useTranslation();
	const isCreateMode = isCreateScreen();

	const [localProperties, setLocalProperties] = useState<Record<string, boolean>>(
		isCreateMode ? contentInitialProperties || {} : contentModelProperties,
	);

	useEffect(() => {
		if (!isCreateMode) {
			setLocalProperties(contentModelProperties);
		}
	}, [contentModelProperties, isCreateMode]);

	const onPropertyChange = (event: ChangeEvent<HTMLInputElement>) => {
		try {
			const propSlug = event.target.value;
			const propChecked = event.target.checked;
			const newPropertiesObject = { ...localProperties, [propSlug]: propChecked };

			setLocalProperties(newPropertiesObject); // Update local state
			updateContentProperty({ [ReduxGeneralContentProps.PROPERTIES]: newPropertiesObject }); // Sync with Redux
			onDynamicPropertiesChange && isCreateMode && onDynamicPropertiesChange({ key: propSlug, checked: propChecked });
		} catch (error) {}
	};

	const componentsRows = [
		[
			<FormGroup id={DATA_QA_ATTRIBUTES.PROPERTIES}>
				<Label>{t('properties')}</Label>
				{localProperties &&
					Object.keys(localProperties).length > 0 &&
					Object.keys(localProperties).map((propertySlug: string) => {
						return (
							<ContentProperty
								key={propertySlug}
								name={extractPropertyNameFromRedux(contentProperties, propertySlug)}
								value={localProperties[propertySlug]}
								slug={propertySlug}
								onPropertyChange={onPropertyChange}
							/>
						);
					})}
			</FormGroup>,
		],
	];

	return <div id={DATA_QA_ATTRIBUTES.GENERAL_CONTENT_WRAPPER}>{wrapComponents(componentsRows)}</div>;
};

function mapStateToProps(state: any) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl() || '';
	const propertiesPropNaming = generatePropsNamingByContentType(contentTypeFromUrl, false) + 'Properties';

	return {
		contentModelProperties: state[contentTypeFromUrl][contentTypeFromUrl].properties || [],
		contentProperties: state.contentProperties[propertiesPropNaming] || [],
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const functionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	return {
		updateContentProperty: (propertyObject: Record<string, any>) =>
			functionForDispatch && dispatch(functionForDispatch(propertyObject, extractContentModeBasedOnUrl())),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentProperties);
