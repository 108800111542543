import React, { ChangeEvent } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import BlockModel from '../../../../models/block.model';
import FootballPlayerWidgetModel from '../models/football-player-widget.model';
import PlayerModel from '../../../../../../../models/v2/player/entity/player.model';
import PlayerSelectComponent from '../../../partials/player-select/player-select.component';
import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import {
	playerProfileStatsTabOptions,
	defaultForwarderStatistics,
	defaultKeeperStatistics,
} from '../../football-team/constants/football-team.constants';
import {
	checkIfOddsAutoCheckEnabled,
	createBlock,
} from '../../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import { ElementsSelect } from '../../../partials/elements-select/widget-elements-select';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import RefreshTimeWrapper from '../../../partials/refresh-time-input/refresh-time-wrapper';
import { onRefreshTimeChangeFromMainComponent } from '../../../partials/refresh-time-input/helper';
import { MatchStatuses } from '../../../../../../../constants/event.types';
import { DisplayOddsCheckbox } from '../../odds/components/display-odds-checkbox.component';
import PlayerMatchesSelect from '../../../partials/match-select/match-by-player-select.component';
import PlayerSeasonStatisticsSelect from '../../../partials/player-select/player-season-statistics-select.component';
import SportBookmakerSelect from '../../../../partials/sport-bookmaker-select/sport-bookmaker-select.component';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import PlayerOrTeamMatchModel from '../../../partials/match-select/models/player-or-team-match.model';

type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: FootballPlayerWidgetModel;
	isValid: boolean;
};

export default class FootballPlayerEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetFootballPlayer,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			isValid: false,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { widgetModel: this.state.widgetModel };
		const isValid = validation.validate(this.props.block, data) === true;

		this.setState({ ...this.state, isValid });

		const bookmakerSelectionEnabled = featuresService && featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const playerModel = FootballPlayerWidgetModel.builder(this.state.widgetModel)
			.withBookmakers(bookmakerSelectionEnabled ? this.state.widgetModel.bookmakers : null)
			.build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: playerModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateWidgetModelState = (model: FootballPlayerWidgetModel) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onPlayerSelect = (player: PlayerModel | null) => {
		const defaultStatistics = player && player.position === 'keeper' ? defaultKeeperStatistics : defaultForwarderStatistics;

		this.updateWidgetModelState(
			FootballPlayerWidgetModel.builder(this.state.widgetModel)
				.withPlayer(player ? player : ({} as PlayerModel))
				.withTournamentSeason({} as EntitySeasonsSelectModel)
				.withMatch({} as PlayerOrTeamMatchModel)
				.withPlayerTeams([])
				.withStatsParameters(defaultStatistics)
				.build(),
		);
	};

	onPlayerTeamsSelect = (teams: string[]) => {
		this.updateWidgetModelState(FootballPlayerWidgetModel.builder(this.state.widgetModel).withPlayerTeams(teams).build());
	};

	onSeasonSelect = (season: EntitySeasonsSelectModel | null) => {
		this.updateWidgetModelState(
			FootballPlayerWidgetModel.builder(this.state.widgetModel)
				.withTournamentSeason(season ? season : ({} as EntitySeasonsSelectModel))
				.build(),
		);
	};

	onDisplayMatchChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.checked;

		if (!value) {
			this.updateWidgetModelState(
				FootballPlayerWidgetModel.builder(this.state.widgetModel)
					.withDisplayedMatch(event.target.checked)
					.withMatch({} as PlayerOrTeamMatchModel)
					.withDisplayOdds(false)
					.build(),
			);
			return;
		}

		this.updateWidgetModelState(FootballPlayerWidgetModel.builder(this.state.widgetModel).withDisplayedMatch(event.target.checked).build());
	};

	onMatchSelect = (match: PlayerOrTeamMatchModel) => {
		const isAutoCheckEnabled = checkIfOddsAutoCheckEnabled();
		const displayOdds = match && match.status && match.status.code !== MatchStatuses.FINISHED && isAutoCheckEnabled;

		this.updateWidgetModelState(
			FootballPlayerWidgetModel.builder(this.state.widgetModel).withMatch(match).withDisplayOdds(displayOdds).build(),
		);
	};

	onDisplayStatsParametersChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.checked;
		const defaultStatistics =
			this.state.widgetModel.player && this.state.widgetModel.player.position === 'keeper'
				? defaultKeeperStatistics
				: defaultForwarderStatistics;

		if (!value) {
			this.updateWidgetModelState(
				FootballPlayerWidgetModel.builder(this.state.widgetModel)
					.withDisplayedStatsParameters(event.target.checked)
					.withStatsParameters([])
					.build(),
			);
			return;
		}
		this.updateWidgetModelState(
			FootballPlayerWidgetModel.builder(this.state.widgetModel)
				.withDisplayedStatsParameters(event.target.checked)
				.withStatsParameters(defaultStatistics)
				.build(),
		);
	};

	onStatsParametersChange = (statsParameters: string[]) => {
		if (statsParameters.length > 6) return;
		this.updateWidgetModelState(FootballPlayerWidgetModel.builder(this.state.widgetModel).withStatsParameters(statsParameters).build());
	};

	onDisplayOddsChange = (e: ChangeEvent<HTMLInputElement>) => {
		this.updateWidgetModelState(FootballPlayerWidgetModel.builder(this.state.widgetModel).withDisplayOdds(e.target.checked).build());
	};

	onBookmakerChange = (bookmakers: SportOddsBookmakerModel[]) => {
		this.updateWidgetModelState(FootballPlayerWidgetModel.builder(this.state.widgetModel).withBookmakers(bookmakers).build());
	};

	onDisplaySocialIconsChange = (event: ChangeEvent<HTMLInputElement>) => {
		this.updateWidgetModelState(FootballPlayerWidgetModel.builder(this.state.widgetModel).withSocialIcons(event.target.checked).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, FootballPlayerWidgetModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	render() {
		const { t } = this.props;
		const { widgetModel, isValid } = this.state;
		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const matchNotStarted =
			widgetModel.match && widgetModel.match && widgetModel.match.status && widgetModel.match.status.code !== MatchStatuses.FINISHED;

		const hasSelectedPlayer = widgetModel && widgetModel.player && widgetModel.player.id;
		const showOddsSelection = widgetModel && widgetModel.match && widgetModel.match.id && widgetModel.displayMatch;

		return (
			<div data-qa={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.PLAYER_SELECT}`}>
				<Row>
					<Col>
						<FormGroup>
							<PlayerSelectComponent
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.PLAYER_SELECT}`}
								isBlocky
								isRequired
								isClearable
								isValid={isValid}
								selectedPlayer={widgetModel.player}
								onPlayerSelect={this.onPlayerSelect}
							/>
						</FormGroup>
					</Col>
				</Row>
				{hasSelectedPlayer && (
					<Row>
						<Col>
							<FormGroup>
								<PlayerSeasonStatisticsSelect
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.PLAYER_SEASON_SELECT}`}
									player={widgetModel.player.id}
									selectedSeason={widgetModel.tournamentSeason}
									onSeasonSelect={this.onSeasonSelect}
									onPlayerTeamsSelect={this.onPlayerTeamsSelect}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				{hasSelectedPlayer && (
					<Row className='mb-2'>
						<Col>
							<FormGroup key={`display-match`} check className='radio'>
								<Input
									id={`display-match-checkbox-${this.props.block.id}`}
									key='display-match-input'
									data-qa='display-match-checkbox'
									className='form-check-input'
									type='checkbox'
									name='radios'
									onChange={this.onDisplayMatchChange}
									checked={widgetModel.displayMatch}
								/>
								<Label check key='display-match-label' className='form-check-label' for={`display-match-checkbox-${this.props.block.id}`}>
									{t('display_match_label')}
								</Label>
							</FormGroup>
						</Col>
					</Row>
				)}
				{hasSelectedPlayer && widgetModel.displayMatch && (
					<Row>
						<Col>
							<FormGroup>
								<PlayerMatchesSelect
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.MATCH_BY_STAGE_SELECT}`}
									playerTeams={widgetModel.playerTeams}
									selectedMatch={widgetModel.match}
									onMatchSelect={this.onMatchSelect}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				{showOddsSelection && bookmakerSelectionEnabled && matchNotStarted && (
					<DisplayOddsCheckbox
						blockId={this.props.block.id}
						onDisplayOddsChange={this.onDisplayOddsChange}
						isChecked={widgetModel.displayOdds}
						sport={SportTypes.FOOTBALL}
					/>
				)}
				{showOddsSelection && widgetModel.displayOdds && (
					<SportBookmakerSelect
						isMulti={false}
						isValid={this.state.isValid}
						showAllBookmakers={false}
						bookmakerSelectionEnabled={bookmakerSelectionEnabled}
						t={this.props.t}
						valueList={widgetModel.bookmakers || []}
						onSelect={this.onBookmakerChange}
						contentLanguage={this.props.contentLanguage}
						sportType={SportTypes.FOOTBALL}
						id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.BOOKMAKER_SELECT}`}
						mandatory={false}
					/>
				)}
				{hasSelectedPlayer && (
					<Row className='mb-2'>
						<Col>
							<FormGroup key={`display-statistics`} check className='radio'>
								<Input
									id={`display-statistics-checkbox-${this.props.block.id}`}
									key='display-statistics-input'
									data-qa='display-statistics-checkbox'
									className='form-check-input'
									type='checkbox'
									name='radios'
									onChange={this.onDisplayStatsParametersChange}
									checked={widgetModel.displayStatsParameters}
								/>
								<Label check key='display-statistics-label' className='form-check-label' for={`display-statistics-checkbox-${this.props.block.id}`}>
									{t('display_statistics_label')}
								</Label>
							</FormGroup>
						</Col>
					</Row>
				)}
				{hasSelectedPlayer && widgetModel.displayStatsParameters && (
					<Row className='mt-2' data-qa='football-player-profile-elements-select'>
						<Col>
							<FormGroup>
								<Label htmlFor={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.ELEMENTS}`}>{t('stats_parameters')}</Label>
								<ElementsSelect
									t={t}
									isMulti
									value={widgetModel.statsParameters}
									elements={playerProfileStatsTabOptions}
									onSelect={this.onStatsParametersChange}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				{hasSelectedPlayer && (
					<Row className='mb-2'>
						<Col>
							<FormGroup key={`display-social-icons`} check className='radio'>
								<Input
									id={`display-social-icons-checkbox-${this.props.block.id}`}
									key='display-social-icons-input'
									data-qa='display-social-icons-checkbox'
									className='form-check-input'
									type='checkbox'
									name='radios'
									onChange={this.onDisplaySocialIconsChange}
									checked={widgetModel.displaySocialIcons}
								/>
								<Label
									check
									key='display-social-icons-label'
									className='form-check-label'
									for={`display-social-icons-checkbox-${this.props.block.id}`}
								>
									{t('display_social_icons_label')}
								</Label>
							</FormGroup>
						</Col>
					</Row>
				)}
				<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
			</div>
		);
	}
}
