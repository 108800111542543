import {
	REQUEST_GALLERY_PROPERTIES_RECEIVED,
	REQUEST_ARTICLE_PROPERTIES_RECEIVED,
	REQUEST_VIDEO_PROPERTIES_RECEIVED,
	REQUEST_WIKI_PROPERTIES_RECEIVED,
	REQUEST_BANNER_PROPERTIES_RECEIVED,
	REQUEST_CATEGORY_PROPERTIES_RECEIVED,
} from '../action-creators/properties-action-creator';
import { remapContentItemsFromResponse } from '../../views/Partials/Sidebar/GeneralContentAttributes/subcomponents/type-select/helpers/type-select.helper';

export function propertiesMiddleware() {
	return function (next: any) {
		return function (action: any) {
			if (action) {
				if (action.type === REQUEST_ARTICLE_PROPERTIES_RECEIVED) {
					action.payload = action.payload.data.data.map((item: any) => remapContentItemsFromResponse(item));
				}

				if (action.type === REQUEST_VIDEO_PROPERTIES_RECEIVED) {
					action.payload = action.payload.data.data.map((item: any) => remapContentItemsFromResponse(item));
				}

				if (action.type === REQUEST_GALLERY_PROPERTIES_RECEIVED) {
					action.payload = action.payload.data.data.map((item: any) => remapContentItemsFromResponse(item));
				}

				if (action.type === REQUEST_WIKI_PROPERTIES_RECEIVED) {
					action.payload = action.payload.data.data.map((item: any) => remapContentItemsFromResponse(item));
				}

				if (action.type === REQUEST_BANNER_PROPERTIES_RECEIVED) {
					action.payload = action.payload.data.data.map((item: any) => remapContentItemsFromResponse(item));
				}

				if (action.type === REQUEST_CATEGORY_PROPERTIES_RECEIVED) {
					action.payload = action.payload.data.data.map((item: any) => remapContentItemsFromResponse(item));
				}
			}

			next(action);
		};
	};
}
